<template>
  <header class="pt-8 text-spreedly-gray-600">
    <div class="flex flex-col">
      <div v-if="breadcrumbs" class="flex flex-row">
        <div class="-mt-5 -mb-4">
          <slot name="breadcrumbs"></slot>
        </div>
      </div>
      <div class="flex flex-row justify-between">
        <div
          class="flex flex-row lg:w-1/2"
          :class="{ 'min-w-0': !editableTitle }"
        >
          <button
            type="button"
            id="open-sidenav-menu"
            @click="isOpen = true"
            class="text-spreedly-gray-700 focus:outline-none xl:hidden mr-6 mt-1 lg:-mt-6"
            :class="{
              'mt-2.5 md:-mt-2.5': !tabs,
              '!mt-2 lg:!mt-3': tabs && !breadcrumbs,
            }"
          >
            <svg
              class="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6H20M4 12H20M4 18H11"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <div
            v-if="editableTitle"
            :class="tabs ? 'mb-4' : 'lg:mb-6'"
            class="mt-2 min-w-0 text-ellipsis whitespace-nowrap overflow-hidden"
          >
            <div class="flex flex-row justify-start">
              <slot name="edit-title"></slot>
            </div>
          </div>
          <div v-else-if="!editableTitle" class="min-w-0">
            <h1
              v-if="!logoTitle"
              class="mt-1 text-spreedly-gray-700 text-3xl lg:text-4xl text-ellipsis whitespace-nowrap overflow-hidden"
              :class="tabs ? 'mb-4' : 'md:mb-8'"
            >
              {{ title }}
            </h1>
            <slot v-else name="logo-title"></slot>
          </div>

          <span v-if="tag && tag.displayTag" class="relative inset-y-3">
            <Tag
              :pt="{
                root: {
                  class:
                    tag.severity === 'secondary'
                      ? 'text-sm lg:text-base border border-spreedly-teal bg-spreedly-teal-light text-spreedly-gray-600  font-bold'
                      : '',
                },
                value: {
                  class: tag.severity === 'secondary' ? 'font-bold' : '',
                },
              }"
              class="mx-4 whitespace-nowrap"
              :value="tag.value"
              :severity="tag.severity"
              rounded
            ></Tag>
          </span>
        </div>
        <div class="flex flex-row justify-end w-1/2">
          <TheSearchBar
            v-if="hasAccessToSearch()"
            v-model:expand-search="expandSearch"
            class="mt-1 lg:mt-2 w-full"
            :class="{
              'mx-8': width >= 1200,
              'mx-4': width < 1200,
              'mt-2 lg:mt-4': editableTitle,
            }"
          />

          <router-link
            :to="{ name: 'EnvironmentOverview' }"
            class="focus:outline-none"
          >
            <img
              v-if="width >= 1200 && !expandSearch"
              class="h-8 lg:mt-2"
              :class="{ 'mt-2 lg:mt-4': editableTitle }"
              src="@/assets/logos/full-blue.svg"
              alt="Spreedly Blue Logo"
            />
            <img
              v-else
              :class="{ 'mt-2 lg:mt-4': editableTitle }"
              class="h-8 mt-1 lg:mt-2 min-w-[42.64px]"
              src="@/assets/logos/graphic-mark-blue.svg"
              alt="Spreedly Blue Logo"
            />
          </router-link>
        </div>
      </div>
      <div
        v-if="tabs"
        class="flex flex-nowrap overflow-x-scroll scrollbar-hide my-8"
      >
        <slot name="nav-tabs"></slot>
      </div>
      <div :class="{ 'mb-4': !tabs }">
        <slot name="info"></slot>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import Tag from "primevue/tag";
import { useSidebar } from "@/composables/useSidebar";
import { useSettingsStore } from "@/stores/SettingsStore";
import TheSearchBar from "@/components/TheSearchBar.vue";
import { useBreakpoints } from "@/composables/useBreakpoints";
import { ref } from "vue";

const expandSearch = ref<boolean>(false);
const { width } = useBreakpoints();
const store = useSettingsStore();
const { isOpen } = useSidebar();

defineProps<{
  title: string;
  breadcrumbs?: boolean;
  editableTitle?: boolean;
  tabs?: boolean;
  logoTitle?: boolean;
  tag?: {
    displayTag: boolean;
    value: string;
    severity: "success" | "warning" | "danger" | "info" | "secondary" | string;
  };
}>();

function hasAccessToSearch() {
  return (
    store.hasAccessToGlobalSearch &&
    (store.hasPermission("gateway.read") ||
      store.hasPermission("transaction.read"))
  );
}
</script>
