<template>
  <div>
    <SpreedlyPageTitle :title="$t('user.edit')" breadcrumbs>
      <template v-slot:breadcrumbs>
        <router-link
          :to="{ name: 'OrganizationUserList' }"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
          id="back-to-users-list-button"
        >
          <i class="pi pi-arrow-left relative mr-1 pb-4 text-[0.75rem]"></i>
          {{ $t("user.organization") }}
        </router-link>
      </template>
    </SpreedlyPageTitle>
    <SpreedlyContainer v-if="user && user.key">
      <Message
        v-if="router.options.history.state.successMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="success"
      >
        <i18n-t scope="global" :keypath="'successMessage.userAdded'" tag="p">
          <template v-slot:user>
            <span class="font-bold">{{ user.email }}</span>
          </template>
        </i18n-t>
      </Message>
      <Message
        v-if="displayErrorMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="error"
        :closable="false"
        >{{ $t("errorMessage.generic") }}
      </Message>
      <form class="text-sm text-left mb-4" @submit.prevent="save(v$.$invalid)">
        <div class="border-b border-gray-300">
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <label for="email-input" class="ml-0 lg:ml-8 font-bold">
                {{ $t("email") }}
              </label>
            </div>

            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 pb-4">
                <span class="mr-4 pr-4">{{ user.email }} </span>
                <button
                  type="button"
                  class="text-spreedly-red underline cursor-pointer"
                  @click="confirmRemoveUser(user)"
                  id="remove-user-button"
                >
                  {{ $t("user.remove") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-300">
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <label
                for="first-name-input"
                class="ml-0 lg:ml-8 font-bold relative top-2"
                :class="{ 'p-error': v$.firstName.$invalid && submitted }"
              >
                {{ `${$t("firstName")}*` }}
              </label>
            </div>

            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 pb-4">
                <InputText
                  id="first-name-input"
                  v-model.trim="v$.firstName.$model"
                  :class="{
                    'p-invalid': v$.firstName.$invalid && submitted,
                  }"
                  aria-describedby="first-name-error"
                />
              </div>
              <div v-if="v$.firstName.$invalid && submitted" class="-mt-4 mb-4">
                <small class="p-error">{{
                  v$.firstName.required.$message
                }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-300">
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <label
                for="last-name-input"
                class="ml-0 lg:ml-8 font-bold relative top-2"
                :class="{ 'p-error': v$.lastName.$invalid && submitted }"
              >
                {{ `${$t("lastName")}*` }}
              </label>
            </div>

            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 pb-4">
                <InputText
                  id="last-name-input"
                  v-model.trim="v$.lastName.$model"
                  :class="{ 'p-invalid': v$.lastName.$invalid && submitted }"
                  aria-describedby="last-name-error"
                />
              </div>
              <div v-if="v$.lastName.$invalid && submitted" class="-mt-4 mb-4">
                <small class="p-error">{{
                  v$.lastName.required.$message
                }}</small>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <label
                for="roles-checkbox-group"
                class="ml-0 lg:ml-8 font-bold"
                :class="{ 'p-error': v$.roles.$invalid && submitted }"
              >
                {{ `${$t("user.role")}*` }}
              </label>
            </div>
            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 pb-4">
                <div>
                  <div class="flex mb-2">
                    <div class="flex items-center h-6">
                      <Checkbox
                        v-model="v$.roles.$model"
                        @update:model-value="updateRoles"
                        variant="outlined"
                        value="administrator"
                        input-id="administrator-checkbox"
                        aria-describedby="administrator-checkbox"
                      />
                    </div>
                    <div class="ml-4">
                      <label
                        for="administrator-checkbox"
                        class="font-semibold text-spreedly-gray-700"
                      >
                        {{ $t("administrator") }}
                      </label>
                      <p
                        id="administrator-checkbox-text"
                        class="text-xs font-normal text-spreedly-gray-600"
                      >
                        {{ $t("user.infoText.admin") }}
                      </p>
                    </div>
                  </div>

                  <div class="flex mb-2">
                    <div class="flex items-center h-6">
                      <Checkbox
                        v-model="v$.roles.$model"
                        :disabled="v$.roles.$model.includes('administrator')"
                        variant="outlined"
                        value="environment_manager"
                        input-id="environment-manager-checkbox"
                        aria-describedby="environment-manager-checkbox"
                      />
                    </div>
                    <div class="ml-4">
                      <label
                        for="environment-manager-checkbox"
                        class="font-semibold text-spreedly-gray-700"
                      >
                        {{ $t("environment_manager") }}
                      </label>
                      <p
                        id="environment-manager-checkbox-text"
                        class="text-xs font-normal text-spreedly-gray-600"
                      >
                        {{ $t("user.infoText.environment") }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="flex mb-2"
                    v-if="currentOrganization.can_access_chargify"
                  >
                    <div class="flex items-center h-6">
                      <Checkbox
                        v-model="v$.roles.$model"
                        :disabled="v$.roles.$model.includes('administrator')"
                        variant="outlined"
                        value="billing_manager"
                        input-id="billing-manager-checkbox"
                        aria-describedby="billing-manager-checkbox"
                      />
                    </div>
                    <div class="ml-4">
                      <label
                        for="billing-manager-checkbox"
                        class="font-semibold text-spreedly-gray-700"
                      >
                        {{ $t("billing_manager") }}
                      </label>
                      <p
                        id="billing-manager-checkbox-text"
                        class="text-xs font-normal text-spreedly-gray-600"
                      >
                        {{ $t("user.infoText.billing") }}
                      </p>
                    </div>
                  </div>

                  <div class="flex">
                    <div class="flex items-center h-6">
                      <Checkbox
                        v-model="v$.roles.$model"
                        :disabled="v$.roles.$model.includes('administrator')"
                        variant="outlined"
                        value="analyst"
                        input-id="analyst-checkbox"
                        aria-describedby="analyst-checkbox"
                      />
                    </div>
                    <div class="ml-4">
                      <label
                        for="analyst-checkbox"
                        class="font-semibold text-spreedly-gray-700"
                      >
                        {{ $t("analyst") }}
                      </label>
                      <p
                        id="analyst-checkbox-text"
                        class="text-xs font-normal text-spreedly-gray-600"
                      >
                        {{ $t("user.infoText.analyst") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="v$.roles.$invalid && submitted" class="-mt-4 mb-4">
                <small class="p-error">{{
                  v$.roles.roleLength.$message
                }}</small>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="flex flex-shrink-0 flex-wrap items-center justify-start">
        <SpreedlyButton
          class="mr-4"
          :text="$t('cancel')"
          :inverse="true"
          :disabled="!v$.$anyDirty || formState === 'saving'"
          @click="resetForm()"
        ></SpreedlyButton>
        <SpreedlyButton
          :disabled="!v$.$anyDirty"
          class="!mr-0"
          :icon="{ position: 'left', state: formState }"
          :text="$t(formState)"
          @click="save(v$.$invalid)"
        ></SpreedlyButton>
      </div>
    </SpreedlyContainer>
    <ConfirmDialog id="delete-dialog"></ConfirmDialog>
  </div>
</template>
<script setup lang="ts">
import { onMounted, reactive, ref, watch } from "vue";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import Message from "primevue/message";
import InputText from "primevue/inputtext";
import ConfirmDialog from "primevue/confirmdialog";

import { useSettingsStore } from "@/stores/SettingsStore";
import { useVuelidate } from "@vuelidate/core";
import {
  removeUserFromOrg,
  updateUser,
  type User,
} from "@/services/UserService";

import { storeToRefs } from "pinia";
import { required, roleLength } from "@/i18n/i18n-validators";

import i18n from "@/i18n";
import router from "@/router";
import { onBeforeRouteLeave } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import Checkbox from "primevue/checkbox";

const displayErrorMessage = ref(false);
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const store = useSettingsStore();
const { currentOrganization } = storeToRefs(store);
const user = ref<User>();
const confirm = useConfirm();

const props = defineProps<{
  id: string;
}>();

onMounted(() => {
  loadUsers(false);
});

async function loadUsers(clearCache?: boolean) {
  await store.fillOrganizationUsers(clearCache);
  user.value = store.organizationUsers.find((u: User) => u.key === props.id);
  populateState();
}

const state = reactive({
  firstName: user.value?.firstName || "",
  lastName: user.value?.lastName || "",
  roles: getRoles(user.value?.roles) || [],
});

const rules = {
  firstName: {
    required,
  },
  lastName: {
    required,
  },
  roles: {
    required,
    roleLength: roleLength(1),
  },
};

const submitted = ref(false);
const v$ = useVuelidate(rules, state);

onBeforeRouteLeave((to, from) => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

const updateRoles = () => {
  if (state.roles.includes("administrator")) {
    state.roles = ["analyst", "administrator", "environment_manager"];

    if (currentOrganization.value.can_access_chargify) {
      state.roles.push("billing_manager");
    }
  } else {
    state.roles = [];
  }
};

watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

watch(
  () => currentOrganization.value.key,
  () => {
    router.push({
      name: "OrganizationUserList",
    });
  }
);

function populateState() {
  state.firstName = user.value?.firstName as string;
  state.lastName = user.value?.lastName as string;
  state.roles = getRoles(user.value?.roles) as string[];
}

function resetForm() {
  displayErrorMessage.value = false;
  populateState();
  submitted.value = false;
  v$.value.$reset();
}

function getRoles(roles?: string[]) {
  if (roles) {
    if (roles?.includes("administrator")) {
      roles = [
        "administrator",
        "environment_manager",
        "analyst",
        "billing_manager",
      ];
    }
    if (
      !currentOrganization.value.can_access_chargify &&
      roles.includes("billing_manager")
    ) {
      roles.splice(roles.indexOf("billing_manager"), 1);
    }
  }

  return roles;
}

function buildPayload() {
  const isAdmin = state.roles?.includes("administrator");
  return {
    key: user.value?.key,
    administrator: isAdmin as boolean,
    billing_manager: (isAdmin
      ? false
      : state.roles?.includes("billing_manager")) as boolean,
    environment_manager: (isAdmin
      ? false
      : state.roles?.includes("environment_manager")) as boolean,
    analyst: (isAdmin ? false : state.roles?.includes("analyst")) as boolean,
    first_name: state.firstName as string,
    last_name: state.lastName as string,
  };
}

async function save(isFormInvalid: boolean) {
  if (!v$.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = false;
  submitted.value = true;
  if (isFormInvalid) {
    return;
  }
  formState.value = "saving";
  const response = await updateUser(
    currentOrganization.value.key,
    buildPayload()
  );

  if (response) {
    formState.value = "saved";
    v$.value.$reset();
    await loadUsers(true);
    submitted.value = false;
  } else {
    displayErrorMessage.value = true;
    formState.value = "saveChanges";
  }
}

function confirmRemoveUser(user: User) {
  confirm.require({
    defaultFocus: "reject",
    message: i18n.global.t("confirmations.removeUserFromOrganization.message", {
      user: user.email,
    }),
    header: i18n.global.t("confirmations.removeUserFromOrganization.header"),
    icon: "pi pi-exclamation-triangle",
    accept: () => removeUser(user),
  });
}

async function removeUser(user: User) {
  try {
    await removeUserFromOrg(currentOrganization.value.key, user.key);
    await loadUsers(true);
    await router.push({ name: "OrganizationUserList" });
  } catch (err) {
    alert(i18n.global.t("errorMessage.removeUser"));
  }
}
</script>
