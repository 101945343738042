<template>
  <div class="h-full w-full flex flex-col px-4">
    <div v-if="!recoverConfigurations.length" class="self-center mt-4">
      <span class="text-lg font-light italic text-spreedly-gray-500">{{
        $t("recover.noConfigurations")
      }}</span>
    </div>
    <div v-else>
      <div v-for="config in recoverConfigurations" class="flex flex-col">
        <div
          class="flex flex-row w-full justify-between overflow-hidden drop-shadow-sm my-2 rounded-lg bg-white border text-spreedly-gray-600 p-4"
        >
          <div class="self-center w-1/3">
            <div class="min-w-0">
              <button
                :title="config.description"
                :id="`edit-recover-config-${config.key}`"
                class="w-full underline font-bold text-lg text-start text-ellipsis whitespace-nowrap overflow-hidden"
                @click="$emit('openEditor', config)"
              >
                {{ config.description }}
              </button>
            </div>
          </div>
          <div class="flex flex-col self-center">
            <div>
              <span class="font-bold mr-2">{{ $t("last_modified") }}</span
              ><span>{{ formatDate(config.updated_at!) }}</span>
            </div>
            <div>
              <span class="font-bold mr-2">{{ $t("created_at") }}</span>

              <span>{{ formatDate(config.inserted_at!) }}</span>
            </div>
          </div>
          <div class="self-center">
            <Button
              @click="$emit('openEditor', config)"
              :title="$t('edit')"
              icon="pi pi-cog pi-text-override"
              type="button"
              :id="`recover-configuration-cog-button-${config.key}`"
              class="recover-configuration-cog-button p-button-text p-button-rounded !text-spreedly-gray-600 !shadow-none !hover:text-spreedly-gray-700 hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
            >
            </Button>
          </div>
        </div>
      </div>
    </div>
    <button
      @click="$emit('openEditor')"
      class="mt-4 text-base text-spreedly-blue-600 hover:text-spreedly-blue-700 inline-flex disabled:cursor-not-allowed items-center whitespace-nowrap"
      :class="{ 'justify-center': !recoverConfigurations.length }"
    >
      <SpreedlyIcon :size="16" name="plus" class="mr-2"></SpreedlyIcon>
      <span>{{ $t("recover.addConfiguration") }}</span>
    </button>
  </div>
</template>
<script setup lang="ts">
import Button from "primevue/button";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
import { formatDate } from "@/services/HelperService";
import { useWorkflow } from "@/composables/useWorkflow";
const { recoverConfigurations } = useWorkflow();

defineEmits(["openEditor"]);
</script>
