<template>
  <div>
    <SpreedlyPageTitle :title="$t('env.environment', 2)">
      <template v-slot:info>
        <div
          class="flex flex-col md:flex-col lg:flex-row lg:justify-between py-2"
        >
          <div class="text-spreedly-gray-600 lg:w-2/3 lg:mt-2">
            {{ $t("infoMessage.envHeader") }}
          </div>
          <SpreedlyButton
            v-if="store.hasPermission('organization.create_environment')"
            class="my-2 lg:my-4 lg:ml-4"
            :text="$t('env.create')"
            :icon="{ position: 'left', name: 'plus' }"
            @click="router.push({ name: 'EnvironmentCreate' })"
          ></SpreedlyButton>
        </div>
      </template>
    </SpreedlyPageTitle>
    <div v-if="!loading">
      <div
        class="mb-10 lg:mb-14 flex flex-col lg:flex-row justify-center lg:py-2 lg:bg-spreedly-blue-100 lg:rounded-xl"
      >
        <div
          class="lg:top-8 relative flex flex-row justify-between w-full lg:w-2/3 border rounded-lg lg:rounded-xl drop-shadow-md text-lg lg:text-3xl"
        >
          <input
            v-model.trim="searchStr"
            id="search-environments-input"
            @keyup.enter="filterEnvironments()"
            :placeholder="$t('env.search')"
            class="h-12 lg:h-16 font-light text-center w-full rounded-l-xl px-2 focus:outline-none search-input"
          />
          <button
            v-show="searchStr"
            type="button"
            id="search-environments-button"
            class="bg-white text-spreedly-gray-600 px-4"
            @click="
              searchStr = '';
              filterEnvironments();
            "
          >
            <i class="pi pi-times"></i>
          </button>
          <button
            type="button"
            aria-label="filter environments"
            id="filter-environments"
            class="text-spreedly-blue-600 bg-white px-4 rounded-r-xl border-l"
            @click="filterEnvironments()"
            @keyup.enter="filterEnvironments()"
          >
            <i class="pi pi-search text-lg lg:text-3xl"></i>
          </button>
        </div>
      </div>

      <span class="text-spreedly-gray-700 text-lg" v-if="!searchStr">{{
        $t("showingCount", { count: filteredEnvironments.length })
      }}</span>
      <span class="text-spreedly-gray-700 text-lg" v-else>{{
        $t("showingSearchCount", {
          count: filteredEnvironments.length,
          search: searchStr,
        })
      }}</span>
      <div class="mb-8 max-h-[60vh] overflow-y-auto">
        <div
          v-for="(env, index) in filteredEnvironments"
          :key="index"
          class="w-full inline-flex overflow-hidden drop-shadow-sm my-2 rounded-lg bg-white border"
          :class="{
            'border-spreedly-blue-600 border-2':
              env.key === currentEnvironment.key,
          }"
        >
          <div class="grid grid-cols-3 lg:grid-cols-7 gap-x-4 w-full">
            <div
              class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-1 p-4 text-spreedly-gray-600 col-span-2 lg:col-span-5"
            >
              <div
                class="col-span-1 text-ellipsis whitespace-nowrap overflow-hidden"
              >
                <span
                  class="font-bold text-lg cursor-pointer"
                  id="environment-name"
                  @click="
                    router.push({
                      name: 'EnvironmentSettings',
                      params: { id: env.key },
                    })
                  "
                  >{{ env.name }}</span
                >
              </div>
              <div
                class="col-span-1 text-sm text-ellipsis whitespace-nowrap overflow-hidden"
              >
                <span class="font-bold">{{ $t("creationDate") }}</span>
                <span class="font-normal ml-2">
                  {{ formatDate(env.created_at as string) }}</span
                >
              </div>
              <div
                class="col-span-1 text-sm text-ellipsis whitespace-nowrap overflow-hidden relative flex flex-row -mb-2 lg:pt-2 lg:-mt-2"
              >
                <span class="font-bold">{{ $t("key") }}</span>
                <SpreedlyCopyToClipboard
                  :token="env.key as string"
                  id="copy-environment-key"
                  inline
                ></SpreedlyCopyToClipboard>
              </div>
              <div
                class="col-span-1 text-sm text-ellipsis whitespace-nowrap overflow-hidden -mt-2 lg:mt-0"
              >
                <span class="font-bold">{{
                  $t("payment_method_submission")
                }}</span>
                <span class="font-normal ml-2">
                  {{ env.payment_method_submission }}</span
                >
              </div>
            </div>
            <div
              class="col-span-1 lg:col-span-2 grid grid-cols-1 lg:grid-cols-3 gap-x-4 justify-items-center"
            >
              <div class="col-span-2 self-center">
                <div
                  v-if="env.key === currentEnvironment.key"
                  class="text-sm lg:text-base border-spreedly-blue-600 border text-spreedly-gray-600 bg-spreedly-blue-200 rounded-xl px-2 mx-2 lg:whitespace-nowrap text-center"
                >
                  {{ $t("currentEnvironment") }}
                </div>
                <Button
                  v-else
                  type="button"
                  :id="`switch-environment-${env.key}`"
                  class="text-spreedly-blue-600 hover:text-spreedly-blue-700 text-sm lg:text-base mx-2 lg:whitespace-nowrap"
                  :label="$t('env.switch')"
                  @click="store.changeCurrentEnvironment(env)"
                ></Button>
              </div>

              <div class="col-span-1 self-center">
                <Button
                  :id="`environment-settings-button-${env.key}`"
                  type="button"
                  aria-label="environment settings"
                  icon="pi pi-cog pi-text-override"
                  class="p-button-text p-button-rounded !text-spreedly-gray-600 !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
                  @click="
                    router.push({
                      name: 'EnvironmentSettings',
                      params: { id: env.key },
                    })
                  "
                >
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="filteredEnvironments.length === 0 && environments.length !== 0"
        class="mx-4 w-[calc(100%-2rem)] text-center py-20 flex flex-col text-spreedly-gray-500"
      >
        <span><i class="text-9xl text-spreedly-teal pi pi-search"></i></span>
        <span class="text-2xl">{{ $t("noResults") }}</span>
        <span class="text-base">{{ $t("checkSpelling") }}</span>
      </div>
    </div>
    <div
      v-else
      class="overflow-x-auto bg-white relative border drop-shadow-sm !rounded-lg mx-6 my-2 py-4"
    >
      <SpreedlyLoadingSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";

import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import Button from "primevue/button";

import { watch, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { deepCopy, formatDate } from "@/services/HelperService";
import type { Environment } from "@/services/EnvironmentService";

const router = useRouter();
const route = useRoute();

const filtering = ref(false);

const store = useSettingsStore();
const { currentOrganization, currentEnvironment } = storeToRefs(store);

const searchStr = ref("");
const { environments } = storeToRefs(store);
const loading = ref(false);

onMounted(() => {
  if (route.query.environments) {
    searchStr.value = route.query.environments.toString();
    filterEnvironments();
  }
});

watch(searchStr, (newValue) => {
  if (!filtering.value && (newValue.length > 2 || !newValue)) {
    setTimeout(filterEnvironments, 500);
  }
});

watch(currentOrganization, () => {
  refreshEnvironments();
});

const filteredEnvironments = ref(sortedEnvironments());

function sortedEnvironments() {
  const sortedArray = environments.value.filter(
    (env: Environment) => env.key !== currentEnvironment.value.key
  );
  sortedArray.unshift(currentEnvironment.value);
  return sortedArray;
}
function filterEnvironments() {
  filtering.value = true;
  let searchCriteria: string[] = [];
  if (searchStr.value) {
    searchCriteria = searchStr.value.trim().toLowerCase().split(" ");
  } else {
    filteredEnvironments.value = sortedEnvironments();
    router.push({ query: {} });
    filtering.value = false;
    return;
  }

  if (searchCriteria.length === 1) {
    filteredEnvironments.value = environments.value.filter((el) => {
      const element = `${el.name} ${el.key} ${
        el.payment_method_submission
      } ${formatDate(el.created_at as string)}`.toLowerCase();
      if (element.includes(searchCriteria[0])) return el;
    });
  } else {
    const searchRegExp = new RegExp(searchCriteria.join("|"));
    const resultSet = environments.value.reduce(
      (matchedEnvironments, env: Environment) => {
        const element = `${env.name} ${env.key} ${
          env.payment_method_submission
        } ${formatDate(env.created_at as string)}`.toLowerCase();
        if (element.match(searchRegExp)) matchedEnvironments.add(env);
        return matchedEnvironments;
      },
      new Set<Environment>()
    );
    filteredEnvironments.value = Array.from(resultSet);
  }
  filtering.value = false;
  router.push({ query: { environments: searchStr.value } });
}
async function refreshEnvironments() {
  loading.value = true;
  await store.fillEnvironments(true);
  filterEnvironments();
  loading.value = false;
}
</script>
