import http, { get } from "@/http";
import * as Sentry from "@sentry/vue";
import type { ChipColumn, Link, Row } from "@/models/spreedly-table.model";
import {
  formatDate,
  sentenceCase,
  truncateDisplayValue,
} from "@/services/HelperService";
import i18n from "@/i18n/index";

export const TRANSACTION_TYPES_WITH_AMOUNTS = [
  "Purchase",
  "Authorize",
  "Capture",
  "Void",
  "Credit",
  "General Credit",
  "Verify",
];

type PaymentMethod = {
  full_name?: string;
  last_four_digits?: string;
  card_type?: string;
  payment_method_type?: string;
};

type TransactionPayload = {
  token: string;
  created_at: string;
  transaction_type: string;
  state: string;
  succeeded: boolean;
  message: string;
  message_key: string;
  amount?: number;
  amount_formatted?: string;
  currency_code?: string;
  gateway_type?: string;
  gateway_token?: string;
  gateway?: {
    name: string;
    gateway_type?: string;
    token: string;
  };
  receiver?: {
    receiver_type: string;
    hostnames: string;
  };
  payment_method?: PaymentMethod;
  raw_json?: string;
  environment_key?: string;
  organization_key?: string;
};

export type Transaction = {
  token: string;
  transaction_type: string;
  status: ChipColumn;
  succeeded: boolean;
  message: string;
  message_key: string;
  raw_json: string;
  amount?: string;
  time: string;
  currency_code?: string;
  gateway?: unknown;
  gateway_token?: string;
  receiver?: {
    receiver_type: string;
    hostnames: string;
  };
  payment_method?: string;
  environment_key?: string;
  organization_key?: string;
};

type TransactionTranscript = string;

export async function fetchTranscript(
  orgKey: string,
  envKey: string,
  txnToken: string
): Promise<TransactionTranscript> {
  const url = `/organizations/${orgKey}/environments/${envKey}/transactions/${txnToken}/transcript`;
  try {
    return (await http().get<{ transcript: TransactionTranscript }>(url)).data
      .transcript;
  } catch (err) {
    Sentry.captureException(err);
    return "";
  }
}

export async function fetchTransaction(
  orgKey: string,
  envKey: string,
  txnToken: string
): Promise<Transaction> {
  const url = `/organizations/${orgKey}/environments/${envKey}/transactions/${txnToken}`;
  const transactionPayload = await get<TransactionPayload>(url, "transaction");
  return {
    token: transactionPayload.token,
    transaction_type: transactionPayload.transaction_type,
    status: {
      displayValue: displayTransactionType(
        transactionPayload.state,
        transactionPayload.succeeded
      ),
      class: setStatusChipColor(
        transactionPayload.state,
        transactionPayload.succeeded
      ),
    },
    payment_method: formatPaymentMethodDescription(transactionPayload),
    gateway: displayGatewayType(transactionPayload),
    gateway_token: displayGatewayToken(transactionPayload),
    time: formatDate(transactionPayload.created_at),
    amount: transactionPayload.amount_formatted,
    succeeded: transactionPayload.succeeded,
    message: transactionPayload.message,
    message_key: transactionPayload.message_key,
    raw_json: JSON.stringify(transactionPayload.raw_json, undefined, 4),
    environment_key: transactionPayload.environment_key,
    organization_key: transactionPayload.organization_key,
  };
}

export async function fetchRecentTransactions(
  orgKey: string,
  envKey: string,
  gatewayKey?: string
): Promise<Row[]> {
  const url = gatewayKey
    ? `/organizations/${orgKey}/environments/${envKey}/gateways/${gatewayKey}/list_recent_transactions`
    : `/organizations/${orgKey}/environments/${envKey}/transactions/`;
  try {
    const response = await http().get<{ transactions: TransactionPayload[] }>(
      url
    );
    return response.data.transactions.map((txn) => ({
      token: {
        displayValue: txn.token,
        to: {
          name: "TransactionDetailSummary",
          params: { id: txn.token },
        },
      } as Link,
      time: txn.created_at,
      transaction_type: txn.transaction_type,
      state: {
        displayValue: displayTransactionType(txn.state, txn.succeeded),
        class: setStatusChipColor(txn.state, txn.succeeded),
      } as ChipColumn,
      payment_method: formatPaymentMethodDescription(txn),
      gateway_token: {
        displayValue: truncateDisplayValue(displayGatewayToken(txn)),
        to: {
          name: "GatewayDetailSummary",
          params: { id: displayGatewayToken(txn) },
        },
      } as Link,
      amount: TRANSACTION_TYPES_WITH_AMOUNTS.includes(txn.transaction_type)
        ? txn.amount_formatted
        : "",
    }));
  } catch (err) {
    Sentry.captureException(err);
    return [];
  }
}

function displayGatewayType(txn: TransactionPayload) {
  if (txn.gateway_type) return sentenceCase(txn.gateway_type);
  if (txn.gateway?.gateway_type) return sentenceCase(txn.gateway.gateway_type);
  return null;
}

function displayGatewayToken(txn: TransactionPayload) {
  if (txn.gateway_token) return txn.gateway_token;
  if (txn.gateway?.token) return txn.gateway.token;
}

function formatPaymentMethodDescription(txn: TransactionPayload) {
  if (txn.payment_method && txn.receiver) {
    return `${paymentMethodDescription(
      txn.payment_method
    )} and ${receiverDescription(
      txn.receiver.receiver_type,
      txn.receiver.hostnames
    )}`;
  } else if (!txn.payment_method && txn.receiver) {
    return receiverDescription(
      txn.receiver.receiver_type,
      txn.receiver.hostnames
    );
  } else if (txn.payment_method && !txn.receiver) {
    return paymentMethodDescription(txn.payment_method);
  }
  return "";
}

function paymentMethodDescription(paymentMethod: PaymentMethod): string {
  if (paymentMethod.full_name) {
    return `${paymentMethod.full_name}'s  ${description(paymentMethod)}`;
  }
  return description(paymentMethod);
}

function description(paymentMethod: PaymentMethod): string {
  if (paymentMethod.last_four_digits) {
    return `${i18n.global.t("payment_method_display_type", {
      paymentMethod: paymentMethodDisplayType(paymentMethod),
      lastFour: paymentMethod.last_four_digits,
    })}`;
  }
  return paymentMethodDisplayType(paymentMethod);
}

function paymentMethodDisplayType(paymentMethod: PaymentMethod): string {
  if (paymentMethod.card_type) {
    switch (paymentMethod.card_type) {
      case "master":
        return "MasterCard";
      case "american_express":
        return "American Express";
      case "_":
        return i18n.global.t("card.unknown");
      default:
        return sentenceCase(paymentMethod.card_type);
    }
  } else if (paymentMethod.payment_method_type) {
    return sentenceCase(paymentMethod.payment_method_type);
  }

  return "";
}

function receiverDescription(type: string, hostname: string): string {
  return `${type} receiver to ${hostname}`;
}

function displayTransactionType(status: string, succeeded: boolean): string {
  if (status) {
    return sentenceCase(status);
  }
  return succeeded ? "Successful" : "Failed";
}

function setStatusChipColor(status: string, succeeded: boolean): string {
  if (status) {
    switch (status.toLowerCase()) {
      case "succeeded":
        return "bg-success-green text-white";
      case "failed":
      case "gateway_processing_failed":
        return "bg-spreedly-red text-white";
      default:
        return "bg-warning-orange text-spreedly-gray-700";
    }
  }
  return succeeded
    ? "bg-success-green text-white"
    : "bg-spreedly-red text-white";
}
