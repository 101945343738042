<template>
  <button
    type="button"
    :disabled="disabled || icon?.state === 'saving' || icon?.state === 'saved'"
    class="max-h-[42px] inline-flex disabled:cursor-not-allowed justify-center items-center font-light rounded whitespace-nowrap border"
    :class="buttonClass"
  >
    <span v-if="icon?.position === 'right'">{{ text }}</span>
    <SpreedlyIcon
      :data-testid="`icon-${icon?.position}-${icon?.name}`"
      v-if="icon && !icon.state"
      :size="iconSize"
      :class="iconClasses"
      :name="icon?.name"
    ></SpreedlyIcon>
    <SpreedlyIcon
      :data-testid="`icon-saving`"
      v-if="icon && icon.state === 'saving'"
      class="mr-4"
      name="dots-circle"
      :spin="true"
    ></SpreedlyIcon>
    <SpreedlyIcon
      :data-testid="`icon-saved`"
      v-if="icon && icon.state === 'saved'"
      class="mr-4"
      name="check"
    ></SpreedlyIcon>
    <span v-if="!icon || icon?.position === 'left'">{{ text }}</span>
  </button>
</template>
<script setup lang="ts">
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
import { computed } from "vue";

const props = defineProps<{
  icon?: {
    name?: string;
    position: string;
    state?: "saveChanges" | "saving" | "saved";
  };
  text: string;
  inverse?: boolean;
  disabled?: boolean;
  size?: "xs" | "sm";
}>();

const buttonClass = computed(() => {
  return `${calculateSize()} ${calculateColors()}`;
});

const iconSize = computed(() => {
  return { xs: 12, sm: 18 }[props.size as keyof { xs: 12; sm: 18 }] || 24;
});

const iconClasses = computed(() => {
  const position = { right: "l", left: "r" }[
    props.icon?.position as keyof { right: "l"; left: "r" }
  ];
  const space = { xs: 1, sm: 1 }[props.size as keyof { xs: 1; sm: 1 }] || 2;

  return position ? `m${position}-${space}` : undefined;
});

function calculateSize() {
  switch (props.size) {
    case "xs":
      return "text-xs px-2 py-1";
    case "sm":
      return "text-sm px-2 py-1";
    default:
      return "text px-4 py-2";
  }
}

function calculateColors() {
  if (props.inverse) {
    return "bg-white text-spreedly-blue-600 border-spreedly-blue-600 disabled:text-spreedly-gray-400 disabled:border-spreedly-gray-200 disabled:bg-white hover:bg-blue-50 hover:border-spreedly-blue-600 active:bg-blue-50 active:border-spreedly-blue-600 focus:bg-blue-50 focus:border-spreedly-blue-600";
  } else {
    switch (props.icon?.state) {
      case "saving":
        return "border-spreedly-blue-600 text-spreedly-blue-600 bg-spreedly-blue-50";
      case "saved":
        return "bg-success-green text-white border-success-green";
      default: //saveChanges
        return "bg-spreedly-blue-600 text-white border-spreedly-blue-600 disabled:text-spreedly-gray-400 disabled:border-spreedly-gray-200 disabled:bg-spreedly-gray-200 hover:bg-spreedly-blue-700 hover:border-spreedly-blue-700 active:bg-spreedly-blue-700 active:border-spreedly-blue-700 focus:bg-spreedly-blue-700 focus:border-spreedly-blue-700";
    }
  }
}
</script>
