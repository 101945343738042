<template>
  <div>
    <div class="flex flex-row">
      <div class="self-center mx-2">
        <Dropdown
          aria-label="Workflow Gateways Dropdown"
          :disabled="!hasPermission"
          :input-id="`workflow-gateway-dropdown-${stepIndex}-${conclusionIndex}`"
          :loading="loadingGateways"
          :show-clear="!!v.gateway_key.$model"
          v-model="v.gateway_key.$model"
          :options="gateways"
          @change="updateWorkflow()"
          :option-disabled="disableOption"
          option-value="key"
          :pt="{
            input: { class: 'pl-0 pr-2 font-sans' },
            root: {
              class:
                v.gateway_key.$invalid && submitted
                  ? '!border-spreedly-red !mb-0 text-spreedly-gray-500 !w-[300px]'
                  : '!mb-0 text-spreedly-gray-500 !w-[300px]',
            },
          }"
        >
          <template #dropdownicon>
            <i class="pi pi-chevron-down text-gray-500 self-center text-lg"></i>
          </template>
          <template #value>
            <div v-if="v.gateway_key.$model">
              <div class="flex flex-row" v-if="selectedGatewayConnection">
                <div class="pr-2 border-r-2">
                  <img
                    :src="
                      getPartnerImageSrc(
                        `${
                          gatewayOption(selectedGatewayConnection.gateway_type)
                            ?.partner
                        }_small`
                      )
                    "
                    :alt="`${
                      gatewayOption(selectedGatewayConnection.gateway_type)
                        ?.company_name
                    } logo`"
                    class="w-fit max-h-5 mt-0.5 flex-shrink-0"
                  />
                </div>
                <div
                  class="px-2 mr-2 text-sm text-ellipse overflow-hidden whitespace-nowrap"
                >
                  {{
                    gatewayOption(selectedGatewayConnection.gateway_type)?.name
                  }}
                </div>
              </div>
            </div>
            <div
              v-else-if="v.gateway_key.$invalid && submitted"
              class="p-error"
            >
              {{ $t("selectionRequired") }}
            </div>
            <div v-else>{{ $t("gateway_.select") }}</div>
          </template>
          <template #option="slotProps">
            <div class="flex flex-col text-sm">
              <div class="flex flex-row">
                <div>
                  {{ slotProps.option.description }}
                </div>
                <div
                  v-if="slotProps.option.sandbox"
                  class="px-1 rounded-xl text-white bg-success-green text-[12px]"
                  :class="slotProps.option.description ? 'mx-4' : 'mx-0'"
                >
                  {{ $t("sandbox") }}
                </div>
              </div>
              <div>
                {{ sentenceCase(slotProps.option.gateway_type) }}
              </div>
              <div>
                {{ `${$t("token")}: ${slotProps.option.key}` }}
              </div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="self-center mx-2">
        <button
          :id="`open-network-token-overlay-${stepIndex}-${conclusionIndex}`"
          :disabled="recover"
          @click="toggleNetworkTokenOverlay"
          class="w-full open-network-token-overlay"
          type="button"
        >
          <div class="flex flex-row">
            <div class="flex flex-col justify-end">
              <div
                class="rounded-2xl text-sm w-full px-2 mb-1 justify-items-end border whitespace-nowrap"
                :class="{
                  'bg-spreedly-gray-200 border-spreedly-gray-200 text-spreedly-gray-400':
                    !isAvEnabled,
                  'bg-white border-spreedly-gray-400 text-spreedly-gray-700':
                    isAvEnabled && !v.params.attempt_network_token.$model,
                  'bg-spreedly-blue-200 border-spreedly-blue-600 text-spreedly-gray-600':
                    isAvEnabled && v.params.attempt_network_token.$model,
                }"
              >
                {{ $t("networkTokens") }}
              </div>
              <div
                v-if="!recover"
                class="rounded-2xl text-sm w-full px-2 border whitespace-nowrap"
                :class="{
                  'bg-spreedly-gray-200 border-spreedly-gray-200 text-spreedly-gray-400 ':
                    !isAvEnabled,
                  'bg-white border-spreedly-gray-400 text-spreedly-gray-700':
                    isAvEnabled && !v.params.pan_fallback.$model,
                  'bg-spreedly-blue-200 border-spreedly-blue-600 text-spreedly-gray-600':
                    isAvEnabled && v.params.pan_fallback.$model,
                }"
              >
                {{ $t("panFallback") }}
              </div>
            </div>
            <i
              v-if="!recover"
              class="pi pi-chevron-down text-gray-500 ml-4 self-center text-lg"
            ></i>
          </div>
        </button>
      </div>
    </div>
    <OverlayPanel
      :id="`network-token-overlay-panel-${stepIndex}-${conclusionIndex}`"
      ref="networkTokenOverlayPanel"
      :pt="{
        root: { class: 'rounded-lg -mt-2 font-sans text-spreedly-gray-600' },
      }"
    >
      <div
        class="flex flex-col"
        :class="{
          'bg-transparent p-2': isAvEnabled,
          'bg-spreedly-blue-200 p-4 mt-2 rounded-md': !isAvEnabled,
        }"
      >
        <div class="flex flex-row">
          <mdicon
            v-if="!isAvEnabled"
            name="information"
            class="text-spreedly-blue-700 pr-2 -ml-2"
            data-testid="info-icon"
          ></mdicon>
          <h1 class="font-bold">{{ $t("networkTokenization") }}</h1>
        </div>
        <i18n-t
          scope="global"
          keypath="networkTokenDocumentation"
          tag="p"
          class="whitespace-pre-line text-sm"
          :class="{ 'pl-6': !isAvEnabled }"
        >
          <template v-slot:learnMore>
            <a
              class="network-tokenization-docs-link underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
              :href="`${docsUrl}/docs/network-tokenization`"
              target="_blank"
              >{{ $t("learnMore") }}</a
            >
          </template>
        </i18n-t>
        <div v-if="!store.currentOrganization.allow_payment_method_management">
          <i18n-t
            scope="global"
            keypath="networkTokenizationNotAllowed"
            tag="p"
            class="whitespace-pre-line mt-2 text-sm pl-6"
          >
            <template v-slot:support>
              <a
                class="mail-spreedly-support-link underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                href="mailto:support@spreedly.com"
                >{{ $t("spreedlySupport") }}</a
              >
            </template>
          </i18n-t>
        </div>
        <div v-else>
          <div
            v-if="
              !store.currentOrganization.payment_method_management_enabled ||
              !store.currentEnvironment.payment_method_management_enabled
            "
          >
            <i18n-t
              scope="global"
              keypath="advancedVault.networkTokenization.disabled"
              tag="p"
              class="whitespace-pre-line mt-2 text-sm pl-6"
            >
              <template v-slot:settings>
                <router-link
                  :id="`advanced-vault-settings-link-${stepIndex}-${conclusionIndex}`"
                  class="advanced-vault-settings-link text-spreedly-blue-600 underline hover:text-spreedly-blue-700 hover:cursor-pointer advanced-vault-settings-link"
                  :to="{ name: 'AdvancedVault' }"
                  >{{ $t("advancedVault.advancedVault") }}</router-link
                >
              </template>
            </i18n-t>
          </div>
        </div>
      </div>
      <div v-if="isAvEnabled" class="m-4">
        <h1 class="pb-2">{{ $t("networkTokens") }}</h1>
        <div class="flex flex-row">
          <Checkbox
            v-model="v.params.attempt_network_token.$model"
            variant="outlined"
            :disabled="!hasPermission"
            @update:modelValue="updateWorkflow()"
            binary
            :input-id="`attempt-network-tokenization-check-${stepIndex}-${conclusionIndex}`"
            :aria-describedby="`attempt-network-tokenization-check-${stepIndex}-${conclusionIndex}`"
          />
          <label
            :for="`attempt-network-tokenization-check-${stepIndex}-${conclusionIndex}`"
            class="ml-2 -mt-1"
          >
            {{ $t("attemptUsingNetworkToken") }}
          </label>
        </div>
        <div class="flex flex-row mt-2 pl-6">
          <Checkbox
            v-model="v.params.pan_fallback.$model"
            :disabled="!v.params.attempt_network_token.$model || !hasPermission"
            variant="outlined"
            @update:modelValue="updateWorkflow()"
            binary
            :input-id="`pan-fallback-check-${stepIndex}-${conclusionIndex}`"
            :aria-describedby="`pan-fallback-check-${stepIndex}-${conclusionIndex}`"
          />
          <label
            :for="`pan-fallback-check-${stepIndex}-${conclusionIndex}`"
            class="ml-2 -mt-1"
          >
            {{ $t("fallbackToPAN") }}
          </label>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { sentenceCase } from "@/services/HelperService";
import type {
  GatewayConnection,
  GatewayOption,
} from "@/services/GatewayService";
import { getPartnerImageSrc } from "@/services/GatewayService";
import { useSettingsStore } from "@/stores/SettingsStore";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  updateRecoverResult,
  updateResult,
  useWorkflow,
} from "@/composables/useWorkflow";
import Checkbox from "primevue/checkbox";
import type { WorkflowResult } from "@/services/WorkflowService";

const { loadingGateways, gateways, action, steps, submitted } = useWorkflow();
const store = useSettingsStore();
const networkTokenOverlayPanel = ref();
const docsUrl = import.meta.env.VITE_DOCS_URL;

const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("organization.create_workflow");
});

const props = defineProps<{
  result: WorkflowResult;
  conclusionIndex: number;
  stepIndex: number;
  recover?: boolean;
  recoverPriority?: number;
}>();

const state = reactive(props.result);
const rules = {
  gateway_key: { required },
  gateway_type: {},
  parent_company_id: {},
  params: {
    attempt_network_token: {},
    pan_fallback: {},
  },
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

const isAvEnabled = computed(() => {
  return !!(
    store.currentOrganization.allow_payment_method_management &&
    store.currentOrganization.payment_method_management_enabled &&
    store.currentEnvironment.payment_method_management_enabled
  );
});

const selectedGatewayConnection = computed(() => {
  return gateways.value.find((g) => g.key === state.gateway_key) || null;
});

function updateWorkflow() {
  if (!v.value.$anyDirty) {
    return;
  }

  if (!state.params.attempt_network_token) {
    state.params.pan_fallback = false;
  }

  if (selectedGatewayConnection.value?.gateway_type) {
    state.gateway_type = selectedGatewayConnection.value?.gateway_type;
    state.parent_company_id =
      gatewayOption(selectedGatewayConnection.value?.gateway_type)?.partner ||
      "";
  }
  v.value.$touch();
  if (props.recover && (props.recoverPriority || props.recoverPriority === 0)) {
    updateRecoverResult(
      state,
      props.conclusionIndex,
      props.stepIndex,
      props.recoverPriority
    );
  } else {
    updateResult(state, props.conclusionIndex, props.stepIndex);
  }
}

const gatewayOption = (gateway_type: string): GatewayOption | null => {
  return (
    store.gatewayOptions.find((g) => g.gateway_type === gateway_type) || null
  );
};

const disableOption = (option: GatewayConnection) => {
  return (
    !props.recover &&
    steps.value[props.stepIndex]
      .conclusions!.map((g) => g.result.gateway_key)
      .includes(option.key)
  );
};

const toggleNetworkTokenOverlay = (event) => {
  networkTokenOverlayPanel.value.toggle(event);
};
</script>
