import { http, get, post, httpDelete } from "@/http";
import axios, { AxiosError } from "axios";
import * as Sentry from "@sentry/vue";
import i18n from "@/i18n";

export type UserPayload = {
  email?: string;
  key?: string;
  administrator: boolean;
  environment_manager: boolean;
  billing_manager: boolean;
  analyst: boolean;
  first_name: string;
  last_name: string;
};

export type UserProfilePayload = {
  first_name: string;
  last_name: string;
};

export type IdUserProfileResponse = {
  first_name: string;
  last_name: string;
};

export interface IdUserInfo {
  email: string;
  key: string;
  first_name?: string;
  last_name?: string;
  spreedly_admin: boolean;
  roles?: string[];
  sso?: boolean;
  authorize_ops?: string[];
  last_logged_in?: string;
  feature_flags: {
    access_to_routing_rules: boolean;
    access_to_global_search: boolean;
    spreedly_one_marketplace: boolean;
    access_to_composer: boolean;
  };
}

export interface User {
  key: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  lastLoggedIn?: string;
  roles?: string[];
  sso?: boolean;
  spreedlyAdmin?: boolean;
}

export async function fetchUserInfo(orgKey: string): Promise<IdUserInfo> {
  return await get<IdUserInfo>(`/organizations/${orgKey}/user`, "user");
}

export async function requestPasswordReset(email: string): Promise<boolean> {
  const payload = {
    client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
    email: email,
    connection: import.meta.env.VITE_AUTH0_CONNECTION,
  };
  try {
    const response = await axios.post(
      `https://${
        import.meta.env.VITE_AUTH0_DOMAIN
      }/dbconnections/change_password`,
      payload
    );
    return response.status === 200;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
}

export async function updateUser(
  orgKey: string,
  payload: UserPayload
): Promise<User | null> {
  const url = `/organizations/${orgKey}/users/${payload.key}`;
  try {
    const response = await http().put(url, payload);
    return response.data.user;
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
}

export async function updateUserProfile(
  payload: UserProfilePayload
): Promise<IdUserProfileResponse | null> {
  const url = `/user`;
  try {
    const response = await http().put(url, payload);
    return response.data.user;
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
}

export async function createUser(
  orgKey: string,
  payload: UserPayload
): Promise<User | null | string> {
  const url = `/organizations/${orgKey}/users/`;
  try {
    return await post(url, payload, "user");
  } catch (err) {
    if (
      err instanceof AxiosError &&
      err.response?.data?.error === "api_error_user_already_exists"
    ) {
      return i18n.global.t("api_error_user_already_exists", {
        email: payload.email,
      });
    }
    return null;
  }
}

export async function fetchUsers(orgKey: string): Promise<User[]> {
  return get<IdUserInfo[]>(`/organizations/${orgKey}/users`, "users").then(
    // TODO: Create generic solution within Next or Id to handle the snake_case/camelCase conversion.
    (response) =>
      response.map((u) => ({
        email: u.email,
        lastLoggedIn: u.last_logged_in,
        roles: u.roles,
        key: u.key,
        firstName: u.first_name,
        lastName: u.last_name,
        spreedlyAdmin: u.spreedly_admin,
      }))
  );
}

export async function removeUserFromOrg(
  orgKey: string,
  userKey: string
): Promise<void> {
  const url = `/organizations/${orgKey}/users/${userKey}`;
  return httpDelete(url);
}
