<template>
  <div class="flex flex-col">
    <div class="flex flex-row">
      <input
        v-if="edit && hasPermission"
        class="pl-2 bg-transparent border-x-transparent border-t-transparent border-2 pb-1 h-fit border-b-spreedly-blue-600 border-dashed text-spreedly-gray-700 text-2xl lg:text-4xl text-ellipsis whitespace-nowrap overflow-hidden !focus:shadow-none outline-none"
        :class="{ 'border-b-spreedly-red': v$.$invalid }"
        id="description-input"
        @keyup.esc="reset()"
        @focusout="saveTitle(v$.$invalid, true)"
        @keyup.enter="saveTitle(v$.$invalid)"
        ref="editInput"
        maxlength="100"
        v-model.trim="v$.title.$model"
        :placeholder="title || altTitle"
        aria-describedby="description-error"
      />
      <h1
        tabindex="0"
        v-tooltip.bottom="$t('rename')"
        @keyup.tab="editTitle()"
        @click="editTitle()"
        id="edit-title"
        class="max-w-[200px] min-w-[50px] hover:border-spreedly-blue-600 rounded xl:max-w-[500px] text-spreedly-gray-700 pb-1 border-2 border-transparent px-2 lg:text-4xl text-ellipsis whitespace-nowrap overflow-hidden"
        v-if="!edit || !hasPermission"
      >
        {{ title || altTitle }}
      </h1>
      <div class="hidden p-2" ref="editIcon" v-if="hasPermission">
        <SpreedlyIcon
          class="text-spreedly-blue-600"
          :class="{ 'text-spreedly-red': v$.$invalid }"
          :spin="formState === 'saving'"
          :name="
            formState === 'saved' || formState === 'saveChanges'
              ? 'pencil'
              : 'dots-circle'
          "
        ></SpreedlyIcon>
      </div>
    </div>
    <div v-if="v$.title.$errors.length && edit">
      <small
        v-for="error in v$.title.$errors"
        :key="error.$uid"
        class="p-error"
        >{{ error.$message }}</small
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, reactive, ref, watch, defineModel, onMounted } from "vue";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";

import { useVuelidate } from "@vuelidate/core";
import { onBeforeRouteLeave } from "vue-router";
import i18n from "@/i18n";
import { required } from "@vuelidate/validators";
import { utf8Validator } from "@/i18n/i18n-validators";

const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const title = defineModel("title", { required: true });
const titleUpdateSuccessful = defineModel("titleUpdateSuccessful", {
  required: true,
});
const emit = defineEmits(["updateTitle"]);
const edit = ref(false);

const props = defineProps<{
  hasPermission: boolean;
  required: boolean;
  altTitle?: string;
}>();

const state = reactive<Record<string, unknown>>({
  title: "",
});

const rules = reactive<Record<string, unknown>>({
  title: props.required ? { required, utf8Validator } : { utf8Validator },
});

const v$ = useVuelidate(rules, state, { $scope: false });

const editInput = ref<HTMLInputElement | null>(null);
const editIcon = ref<HTMLDivElement | null>(null);

function reset() {
  v$.value.$reset();
  state.title = title.value;
  formState.value = "saveChanges";
  edit.value = false;
  if (editIcon.value) {
    editIcon.value.classList.add("hidden");
    editIcon.value.classList.remove("visible");
  }
}

function editTitle() {
  formState.value = "saveChanges";
  edit.value = true;
  nextTick(() => {
    if (editInput.value) {
      editInput.value.focus();
      editInput.value.select();
    }

    if (editIcon.value) {
      editIcon.value.classList.remove("hidden");
      editIcon.value.classList.add("visible");
    }
  });
}

function saveTitle(isFormInvalid: boolean, focusOut?: boolean) {
  if (!v$.value.$anyDirty || formState.value === "saving") {
    if (focusOut) reset();
    return;
  }
  if (isFormInvalid) {
    if (focusOut) reset();
    return;
  }

  formState.value = "saving";
  emit("updateTitle", state.title);
}

function populateState() {
  state.title = title.value;
}

onMounted(() => {
  populateState();
});

onBeforeRouteLeave((to, from) => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

watch(
  () => titleUpdateSuccessful.value,
  () => {
    if (titleUpdateSuccessful.value) {
      edit.value = false;
      formState.value = "saved";
      reset();
      titleUpdateSuccessful.value = null;
    } else {
      formState.value = "saveChanges";
    }
  }
);
</script>
