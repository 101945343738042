<template>
  <SpreedlyTable
    :headers="headers"
    :caption="$t('gatewayTransactionsTable')"
    :rows="transactions"
    :sort="sort"
    :custom-columns="false"
    custom-top-level
    show-refresh
    :is-loading="isLoading"
    @refresh-data="getRecentGatewayTransactions"
  >
  </SpreedlyTable>
</template>
<script lang="ts" setup>
import SpreedlyTable from "@/components/SpreedlyTable.vue";
import { onMounted, ref, watch } from "vue";
import type { Header, Row } from "@/models/spreedly-table.model";
import { fetchRecentTransactions } from "@/services/TransactionService";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import type { Gateway } from "@/models/routing-rules/gateway.model";
import router from "@/router";

const store = useSettingsStore();
const { currentOrganization, currentEnvironment } = storeToRefs(store);
const transactions = ref<Row[]>([]);
const isLoading = ref();

const props = defineProps<{
  id: string;
  parentId?: string;
  gateway: Gateway;
}>();

onMounted(async () => {
  if (!props.gateway.key) {
    await router.push({
      name: props.parentId
        ? "PartnerGatewayDetailSummary"
        : "GatewayDetailSummary",
    });
  }
  await getRecentGatewayTransactions();
});

watch(
  () => currentEnvironment.value.key,
  () => {
    getRecentGatewayTransactions();
  }
);

async function getRecentGatewayTransactions() {
  isLoading.value = true;

  transactions.value = await fetchRecentTransactions(
    currentOrganization.value.key,
    currentEnvironment.value.key as string,
    props.gateway.key as string
  );

  isLoading.value = false;
}

const sort = {
  field: "token",
  order: "asc",
};

const headers: Header[] = [
  {
    field: "token",
    sortable: false,
    type: "Link",
  },
  {
    field: "transaction_type",
    sortable: false,
    type: "String",
    alias: "transactions.type",
  },
  {
    field: "state",
    sortable: false,
    type: "ChipColumn",
    alias: "status",
  },
  {
    field: "payment_method",
    sortable: false,
    type: "String",
  },
  {
    field: "time",
    sortable: false,
    type: "DateString",
  },
  {
    field: "amount",
    sortable: false,
    type: "String",
  },
];
</script>
