<template>
  <div
    :class="width < 1100 ? 'xl:w-full' : 'xl:w-1/3'"
    class="flex flex-col rounded-2xl w-full drop-shadow-md mb-4 justify-between bg-white"
  >
    <div class="rounded-t-2xl pt-4 px-8 mb-4 relative">
      <div class="flex text-2xl font-bold">
        <h2
          class="overflow-hidden whitespace-nowrap text-ellipsis text-spreedly-gray-700"
        >
          {{ title }}
        </h2>
      </div>
      <div
        class="overflow-hidden whitespace-nowrap text-ellipsis text-spreedly-gray-700"
      >
        <slot name="tag"></slot>
      </div>
      <h3 class="font-bold text-lg mb-4 text-spreedly-gray-700">
        {{ subtitle }}
      </h3>
      <p class="text-base text-spreedly-gray-700">
        {{ content }}
      </p>
    </div>
    <div class="flex relative overflow-hidden h-[90px] rounded-b-2xl">
      <slot name="graphic"></slot>
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from "@/composables/useBreakpoints";

const { width } = useBreakpoints();
defineProps<{
  title: string;
  subtitle: string;
  content: string;
}>();
</script>
