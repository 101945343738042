<template>
  <div>
    <SpreedlyPageTitle :title="$t('env.create')"></SpreedlyPageTitle>
    <SpreedlyContainer>
      <Message
        v-if="displayErrorMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="error"
        :closable="false"
        >{{ displayErrorMessage }}
      </Message>
      <form class="text-sm text-left" @submit.prevent="save(v$.$invalid)">
        <div class="border-b border-gray-300">
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <label
                for="name-input"
                class="ml-0 lg:ml-8 font-bold relative top-2"
                :class="{ 'p-error': v$.name.$invalid && submitted }"
              >
                {{ `${$t("name")}*` }}
              </label>
            </div>

            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 pb-4">
                <InputText
                  id="name-input"
                  v-model.trim="v$.name.$model"
                  autofocus
                  :class="{ 'p-invalid': v$.name.$error && submitted }"
                  aria-describedby="name-error"
                />
              </div>
              <div
                v-if="
                  (v$.name.$errors.length && submitted) ||
                  v$.name.$pending.$response
                "
                class="-mt-4 mb-4"
              >
                <small
                  v-for="error in v$.name.$errors"
                  :key="error.$uid"
                  class="p-error"
                  >{{ error.$message }}</small
                >
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <label
                for="payment-method-submission-radio"
                class="ml-0 lg:ml-8 font-bold relative top-6 lg:whitespace-nowrap"
              >
                {{ `${$t("payment_method_submission")}*` }}
              </label>
            </div>

            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 py-2">
                <div class="field">
                  <div
                    id="payment-method-submission-radio"
                    class="mb-4 flex flex-col"
                  >
                    <div class="flex flex-row mb-2">
                      <RadioButton
                        class="lg:ml-1.5"
                        name="iframe-or-express-radio-option"
                        value="iFrame or Spreedly Express"
                        input-id="iframe-or-express-radio-option"
                        v-model="v$.payment_method_submission.$model"
                      />
                      <label
                        class="ml-2 lg:ml-4 text-spreedly-gray-600"
                        for="iframe-or-express-radio-option"
                      >
                        {{ $t("iFrameOrExpress") }}
                      </label>
                    </div>
                    <div class="flex flex-row whitespace-nowrap">
                      <RadioButton
                        class="lg:ml-1.5"
                        name="unrestricted-radio-option"
                        value="Unrestricted"
                        input-id="unrestricted-radio-option"
                        v-model="v$.payment_method_submission.$model"
                      />
                      <label
                        class="ml-2 lg:ml-4 text-spreedly-gray-600 pr-2"
                        for="unrestricted-radio-option"
                      >
                        {{ $t("unrestricted") }}
                      </label>
                      <div>
                        <Button
                          v-tooltip.focus="$t('env.unrestrictedInfoMessage')"
                          severity="secondary"
                          id="tooltip-unrestricted"
                          text
                          rounded
                          :aria-label="$t('unrestricted')"
                          type="button"
                          class="p-button-text p-button-rounded relative -inset-y-2 !text-spreedly-gray-600 !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
                          icon="pi pi-info-circle"
                        >
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        v-if="store.hasPermission('organization.create_environment')"
        class="flex flex-shrink-0 flex-wrap items-center justify-start"
      >
        <SpreedlyButton
          class="mr-4"
          :text="$t('cancel')"
          id="cancel-create-environment-button"
          :inverse="true"
          :disabled="!v$.$anyDirty || formState === 'saving'"
          @click="resetForm()"
        ></SpreedlyButton>
        <SpreedlyButton
          :disabled="!v$.$anyDirty"
          class="!mr-0"
          id="create-environment-button"
          :icon="{ position: 'left', state: formState }"
          :text="$t(formState)"
          @click="save(v$.$invalid)"
        ></SpreedlyButton>
      </div>
    </SpreedlyContainer>
  </div>
</template>

<script setup lang="ts">
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import Button from "primevue/button";
import Message from "primevue/message";
import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";

import { useSettingsStore } from "@/stores/SettingsStore";
import { reactive, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import router from "@/router";
import { createEnvironment } from "@/services/EnvironmentService";
import { required, utf8Validator } from "@/i18n/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import { onBeforeRouteLeave } from "vue-router";
import i18n from "@/i18n";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";

const store = useSettingsStore();
const { currentEnvironment, currentOrganization } = storeToRefs(store);

const displayErrorMessage = ref<string | null>(null);
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const submitted = ref(false);

const state = reactive({
  name: "",
  payment_method_submission: "iFrame or Spreedly Express",
});

const rules = {
  name: { required, utf8Validator },
  payment_method_submission: { required },
};

const v$ = useVuelidate(rules, state);

onBeforeRouteLeave((to, from) => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

function resetForm() {
  displayErrorMessage.value = null;
  state.name = "";
  state.payment_method_submission = "iFrame or Spreedly Express";
  submitted.value = false;
  v$.value.$reset();
}

async function save(isFormInvalid: boolean) {
  if (!v$.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = null;
  if (!store.hasPermission("organization.create_environment")) {
    displayErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }
  submitted.value = true;
  if (isFormInvalid) {
    return;
  }
  formState.value = "saving";
  try {
    const env = await createEnvironment(currentOrganization.value.key, {
      name: state.name,
      payment_method_submission: state.payment_method_submission,
    });
    if (env) {
      v$.value.$reset();
      formState.value = "saved";
      submitted.value = false;
      store.previousOrganization = null;
      await store.fillEnvironments(true);
      await store.changeCurrentEnvironment(env);
      await router.push({
        name: "EnvironmentSettings",
        params: { id: currentEnvironment.value.key },
        state: { successMessage: "true" },
      });
    }
  } catch (error) {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
    formState.value = "saveChanges";
  }
}
</script>
