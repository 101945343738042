<template>
  <div>
    <SpreedlyPageTitle :title="$t('activityLog.activityLog')">
    </SpreedlyPageTitle>
    <div class="mt-4 md:mt-0 mb-8">
      <div class="relative flex flex-row justify-between xl:w-1/3 w-full">
        <SpreedlyButton
          :icon="{ position: 'left', name: 'download' }"
          :inverse="false"
          :text="$t('downloadCsv')"
          @click="downloadCsvData()"
        ></SpreedlyButton>
      </div>
    </div>
    <SpreedlyTable
      v-if="!loadingLogs"
      :headers="headers"
      :caption="$t('activityLog.table')"
      :rows="activityLogs"
      :custom-columns="false"
    >
    </SpreedlyTable>
    <SpreedlyContainer v-else>
      <SpreedlyLoadingSpinner />
    </SpreedlyContainer>
  </div>
</template>
<script setup lang="ts">
import exportFromJSON from "export-from-json";
import { storeToRefs } from "pinia";
import { watch, ref, onMounted } from "vue";
import { fetchActivityLogs } from "@/services/OrganizationService";
import { useSettingsStore } from "@/stores/SettingsStore";
import SpreedlyTable from "@/components/SpreedlyTable.vue";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";

const store = useSettingsStore();
const { currentOrganization } = storeToRefs(store);
const activityLogs = ref();
const loadingLogs = ref(false);

watch(
  () => currentOrganization.value.key,
  () => {
    loadActivityLogs();
  }
);
onMounted(() => {
  loadActivityLogs();
});

const headers = [
  {
    field: "time",
    type: "DateString",
    sortable: false,
  },
  {
    field: "event",
    type: "String",
    sortable: false,
  },
  {
    field: "performedBy",
    type: "String",
    sortable: false,
  },
  {
    field: "target",
    type: "String",
    sortable: false,
  },
  {
    field: "previousState",
    type: "String",
    sortable: false,
  },
  {
    field: "newState",
    type: "String",
    sortable: false,
  },
];

async function loadActivityLogs() {
  loadingLogs.value = true;
  activityLogs.value = await fetchActivityLogs(currentOrganization.value.key);
  loadingLogs.value = false;
}

function downloadCsvData() {
  const todayDate = new Date().toISOString().split("T")[0];
  const data = activityLogs.value;
  const fileName = `spreedly-activity-logs-${todayDate}`;
  const exportType = exportFromJSON.types.csv;

  if (data) exportFromJSON({ data, fileName, exportType });
}
</script>
