<template>
  <Toast
    position="top-right"
    v-if="store.previousEnvironment"
    :pt="{
      container: { class: 'rounded-lg' },
      root: { class: 'opacity-100' },
    }"
  >
    <template #container="{ message, closeCallback }">
      <section
        class="flex flex-row w-full bg-white border border-spreedly-blue-600 rounded-md"
      >
        <div class="bg-spreedly-blue-600 w-[15px] rounded-l"></div>
        <div
          class="m-3 flex flex-row border-y-0 border-l-0 border-r-1 border border-spreedly-gray-500 pr-3 items-center"
        >
          <i class="pi pi-info-circle text-spreedly-gray-700 text-3xl"></i>
          <div
            class="flex flex-col mx-4 font-normal text-spreedly-gray-600 w-[200px]"
          >
            <div>
              {{ message.summary }}
            </div>
            <div
              class="overflow-hidden text-ellipsis whitespace-nowrap"
              :title="store.currentEnvironment.name"
            >
              {{ store.currentEnvironment.name }}
            </div>
          </div>

          <button
            type="button"
            id="undo-environment-switch"
            class="whitespace-nowrap border border-spreedly-blue-600 my-2 px-2 rounded-md text-spreedly-blue-600 bg-white hover:cursor-pointer hover:text-spreedly-blue-700 hover:border-spreedly-blue-700"
            @click="$emit('undo', store.previousEnvironment)"
          >
            {{ $t("undo") }}
          </button>
        </div>
        <div class="flex flex-col justify-center w-7 -ml-2 items-center">
          <button
            type="button"
            aria-label="close environment switch notice"
            id="close-environment-switch-toast"
            @click="closeCallback"
            class="text-spreedly-gray-600 hover:bg-spreedly-blue-300 rounded-4xl w-6 h-6 align-middle"
          >
            <i class="pi pi-times mt-1"></i>
          </button>
        </div>
      </section>
    </template>
  </Toast>
</template>
<script setup lang="ts">
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import Button from "primevue/button";
import { onMounted } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import i18n from "@/i18n";

const store = useSettingsStore();

defineEmits(["undo"]);

const toast = useToast();
onMounted(() => {
  toast.add({
    severity: "info",
    summary: i18n.global.t("env.toast"),
    life: 5000,
  });
});
</script>
