<template>
  <div>
    <div v-if="gatewayOption?.gateway_type">
      <SpreedlyPageTitle
        :title="gatewayOption.name"
        breadcrumbs
        :tag="connectedTag"
      >
        <template v-slot:breadcrumbs>
          <Button
            type="button"
            class="text-spreedly-blue-600 hover:text-spreedly-blue-700"
            id="manage-gateways-button"
            icon="pi pi-arrow-left"
            icon-pos="left"
            text
            :label="$t('marketplace')"
            @click="router.push({ name: 'Marketplace' })"
          ></Button>
        </template>
        <template
          v-slot:info
          v-if="gatewayOption.partnership_tier === 'premier'"
        >
          <i18n-t
            scope="global"
            :keypath="'learnMoreAbout'"
            tag="p"
            class="text-caption -mt-4 mb-4"
          >
            <template v-slot:subject>
              <a
                class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                id="learn-more-about-gateway-link"
                :href="gatewayOption?.homepage"
                target="_blank"
                >{{ gatewayOption?.company_name }}</a
              >
            </template>
          </i18n-t>
        </template>
      </SpreedlyPageTitle>
      <div class="-mt-4 mb-1" v-if="gateways">
        <div
          class="w-full flex flex-row text-spreedly-gray-600"
          :class="{
            'justify-between': store.hasPermission(
              'environment.create_gateway'
            ),
            'justify-end': !store.hasPermission('environment.create_gateway'),
          }"
        >
          <SpreedlyButton
            v-if="store.hasPermission('environment.create_gateway')"
            :text="$t('gateway_.addNewConnection')"
            :icon="{ name: 'plus', position: 'left' }"
            @click="
              router.push({
                name: 'PartnerGatewayCreate',
                params: {
                  gatewayType: gatewayOption.gateway_type,
                },
              })
            "
            inverse
          ></SpreedlyButton>
          <Dropdown
            input-id="gateway-filter"
            aria-labelledby="gateway-filter"
            :highlightOnSelect="false"
            v-model="selectedFilter"
            :options="filterTypes"
            :focus-on-hover="false"
            optionLabel="label"
            checkmark
            class="!w-[14rem]"
            :pt="{
              input: { class: 'font-sans' },
              list: { class: 'font-sans' },
              wrapper: { class: 'p-2' },
              item: {
                class:
                  'first:border first:border-t-0  first:border-x-0 first:border-spreedly-gray-300',
              },
            }"
            @change="filterGateways(selectedFilter.type)"
          />
        </div>
      </div>
      <div
        class="grid grid-cols-1 lg:grid-cols-2 gap-4"
        v-if="filteredConnections.length > 0 && !loadingConnections"
      >
        <div
          class="border rounded-2xl shadow-md h-48 py-4 px-8"
          v-for="connection in filteredConnections"
          :key="connection.key"
        >
          <div class="flex flex-row w-full">
            <Button
              type="button"
              :id="`view-connection-${connection.key}`"
              text
              :label="
                connection.description
                  ? connection.description
                  : gatewayOption.name
              "
              class="text-xl mb-4 hover:underline"
              @click="viewGateway(connection)"
            >
            </Button>
            <div v-if="connection.sandbox" class="ml-4">
              <Tag
                :value="$t('sandbox')"
                :pt="{
                  root: {
                    class:
                      'text-spreedly-gray-600 border border-spreedly-gray-600  bg-white w-fit',
                  },
                  value: { class: 'whitespace-nowrap' },
                }"
                rounded
              ></Tag>
            </div>
          </div>
          <div class="grid grid-cols-4 text-sm">
            <span class="col-span-1 font-bold">{{ $t("gateway_token") }}</span>
            <SpreedlyCopyToClipboard
              class="col-span-3 -my-2 -ml-2"
              :token="connection.key"
            ></SpreedlyCopyToClipboard>
            <span class="col-span-1 -mt-2 font-bold">{{
              $t("creationDate")
            }}</span>
            <span class="col-span-3 -mt-2">{{
              formatDate(connection.created_at)
            }}</span>
          </div>
          <hr class="mt-4 mb-2" />
          <div class="flex flex-row justify-end">
            <Button
              severity="secondary"
              text
              :title="$t('viewSettings')"
              @click="viewGateway(connection)"
              rounded
              :aria-label="$t('settings')"
              type="button"
              class="p-button-text p-button-rounded !text-spreedly-gray-500 !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
              icon="pi pi-cog"
            >
            </Button>
          </div>
        </div>
      </div>
      <div v-else-if="filteredConnections.length === 0 && !loadingConnections">
        {{
          $t("gateway_.noConnectionsForMode", {
            mode: selectedFilter.type === "all" ? "" : selectedFilter.type,
          })
        }}
      </div>
      <SpreedlyLoadingSpinner v-if="loadingConnections" />
    </div>
  </div>
</template>

<script setup lang="ts">
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";

import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Tag from "primevue/tag";

import { computed, onMounted, ref, watch } from "vue";
import router from "@/router";
import { useSettingsStore } from "@/stores/SettingsStore";

import { storeToRefs } from "pinia";
import { formatDate } from "@/services/HelperService";
import {
  fetchGatewayConnectionByGatewayType,
  type GatewayConnection,
  getPartnerImageSrc,
} from "@/services/GatewayService";
import i18n from "@/i18n";

const store = useSettingsStore();
const { currentEnvironment, currentOrganization, gatewayOptions } =
  storeToRefs(store);

const props = defineProps<{
  id: string;
}>();

onMounted(async () => {
  if (gatewayOption?.gateway_type) {
    document.title = `${gatewayOption.name} ${i18n.global.t("gateway", 2)}`;
    if (gatewayOption && gatewayOption.connected) {
      await getGateways();
    }
  }
});

watch(currentOrganization, () => {
  router.push({
    name: "Marketplace",
  });
});

const filterTypes = ref([
  { type: "all", label: i18n.global.t("gateway_.all") },
  { type: "production", label: i18n.global.t("gateway_.production") },
  { type: "sandbox", label: i18n.global.t("gateway_.sandbox") },
]);
const selectedFilter = ref(filterTypes.value[0]);
const gatewayOption = gatewayOptions.value.find(
  (o) => o.gateway_type === props.id
);

const connectedTag = computed(() => {
  return {
    displayTag: true,
    value: gatewayOption?.connected
      ? i18n.global.t("connectionCount", {
          count:
            gatewayOption?.connection_counts.production_count +
            gatewayOption?.connection_counts.sandbox_count,
        })
      : i18n.global.t("notConnected"),
    severity: gatewayOption?.connected ? "success" : "info",
  };
});

const filteredConnections = ref<GatewayConnection[]>([]);
const loadingConnections = ref(false);

const gateways = ref<GatewayConnection[]>([]);

async function getGateways() {
  if (!gatewayOption) return;
  try {
    loadingConnections.value = true;
    gateways.value = await fetchGatewayConnectionByGatewayType(
      currentOrganization.value.key,
      currentEnvironment.value.key as string,
      gatewayOption.gateway_type
    );
    filterGateways("all");
  } finally {
    loadingConnections.value = false;
  }
}

function filterGateways(filterValue: string) {
  switch (filterValue) {
    case "production":
      filteredConnections.value = filterSandboxGateways(false);
      break;
    case "sandbox":
      filteredConnections.value = filterSandboxGateways(true);
      break;
    case "all":
    default:
      filteredConnections.value = gateways.value;
      break;
  }
}

function filterSandboxGateways(sandbox: boolean) {
  return gateways.value.filter((g: GatewayConnection) => {
    return g.sandbox === sandbox;
  });
}

function viewGateway(gateway: GatewayConnection) {
  if (!gatewayOption) return;
  router.push({
    name: "PartnerGatewayDetailSummary",
    params: { id: gateway.key, gatewayType: gatewayOption.gateway_type },
  });
}
</script>
<style lang="css">
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0 transparent !important;
}
</style>
