<template>
  <div>
    <SpreedlyPageTitle :title="$t('user.organization')"></SpreedlyPageTitle>
    <div
      v-if="store.hasPermission('organization.add_user')"
      class="mt-4 md:mt-0 mb-8"
    >
      <div class="relative flex flex-row justify-between xl:w-1/3 w-full">
        <SpreedlyButton
          :text="$t('user.add')"
          :icon="{ position: 'left', name: 'plus' }"
          @click="router.push({ name: 'OrganizationUserCreate' })"
        ></SpreedlyButton>
      </div>
    </div>
    <SpreedlyTable
      v-if="userList.length > 0 && !loadingUsers"
      :headers="headers"
      :sort="sort"
      :caption="$t('user.table')"
      :rows="userList"
      :custom-columns="true"
    >
      <template v-slot:custom-column-headers>
        <th>
          <span>{{ $t("roles") }}</span>
        </th>
        <th :aria-label="$t('user.edit')"></th>
        <th :aria-label="$t('user.remove')"></th>
      </template>
      <template v-slot:custom-columns="{ row }">
        <td>
          <Chip
            class="mr-1 px-3 text-sm my-1 whitespace-nowrap text-spreedly-gray-600 !bg-spreedly-gray-300"
            :label="$t(role)"
            v-for="(role, i) in row.roles"
            :key="i"
          />
        </td>
        <td>
          <Button
            id="user-settings-button"
            aria-label="User Settings"
            v-if="showButton(row.email)"
            @click="
              router.push({
                name: 'OrganizationUserSettings',
                params: {
                  id: row.key,
                },
              })
            "
            icon="pi pi-cog"
            class="p-button-text p-button-rounded !text-spreedly-gray-500 !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
          ></Button>
        </td>
        <td>
          <Button
            v-if="showButton(row.email)"
            @click="confirmRemoveUser(row)"
            id="remove-user-button"
            icon="pi pi-trash"
            :aria-label="$t('user.remove')"
            class="p-button-text p-button-rounded !text-spreedly-red !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
          ></Button>
        </td>
      </template>
    </SpreedlyTable>
    <SpreedlyContainer v-else>
      <SpreedlyLoadingSpinner />
    </SpreedlyContainer>

    <ConfirmDialog id="delete-dialog"></ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { watch, ref, onMounted } from "vue";
import { removeUserFromOrg, type User } from "@/services/UserService";
import { useSettingsStore } from "@/stores/SettingsStore";
import router from "@/router";

import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";
import SpreedlyTable from "@/components/SpreedlyTable.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";

import Chip from "primevue/chip";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";

import { useAuth0 } from "@auth0/auth0-vue";
import { useConfirm } from "primevue/useconfirm";
import i18n from "@/i18n";
import { deepCopy } from "@/services/HelperService";

const { user } = useAuth0();

const store = useSettingsStore();
const { currentOrganization, organizationUsers } = storeToRefs(store);
const userList = ref<User[]>([]);
const loadingUsers = ref(false);
const confirm = useConfirm();

const headers = [
  {
    field: "email",
    sortable: true,
    type: "String",
  },
  {
    field: "firstName",
    sortable: true,
    type: "String",
  },
  {
    field: "lastName",
    sortable: true,
    type: "String",
  },
  {
    field: "lastLoggedIn",
    sortable: true,
    type: "DateString",
  },
];
const sort = {
  field: "email",
  order: "asc",
};

watch(
  () => currentOrganization.value.key,
  () => {
    loadUsers();
  }
);

onMounted(() => {
  loadUsers();
});

function confirmRemoveUser(user: User) {
  confirm.require({
    defaultFocus: "reject",
    message: i18n.global.t("confirmations.removeUserFromOrganization.message", {
      user: user.email,
    }),
    header: i18n.global.t("confirmations.removeUserFromOrganization.header"),
    icon: "pi pi-exclamation-triangle",
    accept: () => removeUser(user),
  });
}

async function removeUser(user: User) {
  try {
    await removeUserFromOrg(currentOrganization.value.key, user.key);
    await loadUsers();
  } catch (err) {
    alert(i18n.global.t("errorMessage.removeUser"));
  }
}

function showButton(email: string): boolean {
  return !email.includes(`(${i18n.global.t("you")})`);
}

async function loadUsers() {
  loadingUsers.value = true;
  try {
    await store.fillOrganizationUsers(true);
    userList.value = deepCopy(organizationUsers.value).map((u: User) => {
      if (u.email === user.value?.email) {
        u.email = `${u.email} (${i18n.global.t("you")})`;
      }
      return u;
    });
  } finally {
    loadingUsers.value = false;
  }
}
</script>
<style scoped lang="css">
.p-button {
  position: inherit;
}
</style>
