<template>
  <div>
    <SpreedlyPageTitle :title="$t('profile')"></SpreedlyPageTitle>

    <SpreedlyContainer>
      <Message
        v-if="displayErrorMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="error"
        :closable="false"
        >{{ $t("errorMessage.generic") }}
      </Message>
      <form
        class="p-fluid mt-4 mb-8 text-spreedly-gray-700"
        @submit.prevent="save(v$.$invalid)"
      >
        <div class="field mb-2">
          <label for="email" class="font-bold text-sm">{{ $t("email") }}</label>
          <div class="mt-2">
            <span>{{ userDeepCopy.email }}</span>
          </div>
        </div>
        <div class="field mb-2">
          <label for="roles" class="font-bold text-sm">{{ $t("roles") }}</label>
          <div class="mt-2">
            <Chip
              class="mr-1 px-3 text-sm my-1 whitespace-nowrap text-spreedly-gray-600 !bg-spreedly-gray-300"
              :label="$t(role)"
              v-for="(role, i) in userDeepCopy.roles"
              :key="i"
            />
          </div>
        </div>
        <div class="field">
          <label
            class="font-bold text-sm"
            for="first-name-input"
            :class="{ 'p-error': v$.first_name.$invalid && submitted }"
            >{{ `${$t("firstName")}*` }}</label
          >
          <div class="p-float-label mt-2 w-full lg:w-2/3 2xl:w-1/3 pb-4">
            <InputText
              id="first-name-input"
              v-model.trim="v$.first_name.$model"
              autofocus
              :class="{ 'p-invalid': v$.first_name.$invalid && submitted }"
              class="sentry-mask"
              aria-describedby="first-name-error"
              type="text"
            />
          </div>
          <div
            v-if="
              (v$.first_name.$invalid && submitted) ||
              v$.first_name.$pending.$response
            "
            class="-mt-4 mb-4"
          >
            <small class="p-error">{{ v$.first_name.required.$message }}</small>
          </div>
        </div>
        <div class="field">
          <label
            class="font-bold text-sm"
            for="last-name-input"
            :class="{ 'p-error': v$.last_name.$invalid && submitted }"
            >{{ `${$t("lastName")}*` }}</label
          >
          <div class="p-float-label mt-2 w-full lg:w-2/3 2xl:w-1/3 pb-4">
            <InputText
              id="last-name-input"
              v-model.trim="v$.last_name.$model"
              autofocus
              :class="{ 'p-invalid': v$.last_name.$invalid && submitted }"
              class="sentry-mask"
              aria-describedby="last-name-error"
              type="text"
            />
          </div>
          <div
            v-if="
              (v$.last_name.$invalid && submitted) ||
              v$.last_name.$pending.$response
            "
            class="-mt-4 mb-4"
          >
            <small class="p-error">{{ v$.last_name.required.$message }}</small>
          </div>
        </div>
        <div class="field mb-2" v-if="!user.sso">
          <label for="password" class="font-bold text-sm">{{
            $t("password")
          }}</label>
          <div class="mt-2 flex flex-row">
            <span>***************</span>
            <button
              type="button"
              class="text-spreedly-blue-600 underline cursor-pointer mx-4 px-4"
              @click="confirmResetPassword"
              id="reset-password-button"
            >
              {{ $t("resetPassword") }}
            </button>
          </div>
        </div>
      </form>

      <div class="flex flex-shrink-0 flex-wrap items-center justify-start">
        <SpreedlyButton
          class="mr-4"
          :text="$t('cancel')"
          :inverse="true"
          :disabled="!v$.$anyDirty || formState === 'saving'"
          @click="resetForm()"
        ></SpreedlyButton>
        <SpreedlyButton
          :disabled="!v$.$anyDirty"
          class="!mr-0"
          :icon="{ position: 'left', state: formState }"
          :text="$t(formState)"
          @click="save(v$.$invalid)"
        ></SpreedlyButton>
      </div>
    </SpreedlyContainer>

    <ConfirmDialog
      group="confirm-reset-password"
      id="warning-dialog"
    ></ConfirmDialog>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, watch } from "vue";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import Chip from "primevue/chip";
import ConfirmDialog from "primevue/confirmdialog";

import Message from "primevue/message";
import InputText from "primevue/inputtext";

import { useSettingsStore } from "@/stores/SettingsStore";
import { useVuelidate } from "@vuelidate/core";
import {
  requestPasswordReset,
  updateUserProfile,
} from "@/services/UserService";
import { useConfirm } from "primevue/useconfirm";

import { storeToRefs } from "pinia";
import { deepCopy } from "@/services/HelperService";
import { required } from "@/i18n/i18n-validators";

import i18n from "@/i18n";
import { useAuth0 } from "@auth0/auth0-vue";
import { onBeforeRouteLeave } from "vue-router";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";

const displayErrorMessage = ref(false);
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const store = useSettingsStore();
const { user } = storeToRefs(store);
const confirm = useConfirm();
const { logout } = useAuth0();

const userDeepCopy = ref(deepCopy(user.value));
const state = reactive({
  first_name: userDeepCopy.value.firstName,
  last_name: userDeepCopy.value.lastName,
});

const rules = {
  first_name: {
    required,
  },
  last_name: {
    required,
  },
};

const submitted = ref(false);
const v$ = useVuelidate(rules, state);
watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

onBeforeRouteLeave((to, from) => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

function resetForm() {
  userDeepCopy.value = deepCopy(user.value);
  state.first_name = userDeepCopy.value.firstName;
  state.last_name = userDeepCopy.value.lastName;
  displayErrorMessage.value = false;
  submitted.value = false;
  v$.value.$reset();
}

async function save(isFormInvalid: boolean) {
  if (!v$.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = false;
  submitted.value = true;
  if (isFormInvalid) {
    return;
  }
  formState.value = "saving";
  const response = await updateUserProfile(state);
  if (response) {
    await store.fillUserInfo(true);
    formState.value = "saved";
    v$.value.$reset();
  } else {
    displayErrorMessage.value = true;
    formState.value = "saveChanges";
  }
}

const confirmResetPassword = () => {
  confirm.require({
    group: "confirm-reset-password",
    message: i18n.global.t("confirmations.resetPassword.message"),
    header: i18n.global.t("resetPassword"),
    icon: "pi pi-info-circle",
    defaultFocus: "reject",
    acceptLabel: i18n.global.t("sendRequest"),
    rejectLabel: i18n.global.t("cancel"),
    accept: () => resetPassword(),
  });
};

async function resetPassword() {
  if (await requestPasswordReset(user.value.email)) {
    await logout({
      logoutParams: {
        returnTo: `${window.location.origin}/password-reset`,
      },
    });
  }
}
</script>
