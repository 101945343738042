<template>
  <div>
    <SpreedlyPageTitle
      :title="sentenceCase($t('org.settings'))"
    ></SpreedlyPageTitle>
    <SpreedlyContainer>
      <Message
        v-if="displayErrorMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="error"
        :closable="false"
        >{{ displayErrorMessage }}
      </Message>
      <form class="text-sm text-left mb-4" @submit.prevent="save(v$.$invalid)">
        <div class="border-b border-gray-300">
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <div class="ml-0 lg:ml-8 font-bold relative top-4">
                {{ $t("key") }}
              </div>
            </div>
            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 py-1">
                <SpreedlyCopyToClipboard
                  id="copy-organization-key"
                  v-if="currentOrganization.key"
                  :token="currentOrganization.key"
                ></SpreedlyCopyToClipboard>
              </div>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-300">
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <label
                for="name-input"
                class="ml-0 lg:ml-8 font-bold relative top-2"
                :class="{ 'p-error': v$.name.$invalid && submitted }"
              >
                {{ `${$t("name")}*` }}
              </label>
            </div>
            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 pb-4">
                <InputText
                  :disabled="
                    !store.hasPermission('organization.update_settings')
                  "
                  id="name-input"
                  v-model.trim="v$.name.$model"
                  autofocus
                  :class="{ 'p-invalid': v$.name.$invalid && submitted }"
                  aria-describedby="name-error"
                />
              </div>
              <div
                v-if="
                  (v$.name.$invalid && submitted) || v$.name.$pending.$response
                "
                class="-mt-4 mb-4"
              >
                <small class="p-error">{{ v$.name.required.$message }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-300 lg:py-4 py-2">
          <div class="grid grid-cols-3 gap-4">
            <div class="col-span-1">
              <label
                for="payment-method-management-enabled-toggle"
                class="ml-0 lg:ml-8 font-bold relative top-2"
              >
                {{ $t("advancedVault.advancedVault") }}
              </label>
            </div>

            <div class="col-span-2 text-spreedly-gray-600 px-2 lg:-px-8">
              <div
                class="grid grid-cols-12"
                v-if="currentOrganization.allow_payment_method_management"
              >
                <div
                  class="col-span-4 lg:col-span-3 xl:col-span-2 2xl:col-span-1"
                >
                  <InputSwitch
                    :disabled="
                      !store.hasPermission('organization.update_settings')
                    "
                    class="relative top-1"
                    input-id="payment-method-management-enabled-toggle"
                    v-model="v$.payment_method_management_enabled.$model"
                  />
                </div>
                <div
                  class="col-span-8 lg:col-span-9 xl:col-span-10 2xl:col-span-11"
                >
                  <i18n-t
                    scope="global"
                    :keypath="'advancedVault.infoMessage.avAllowed'"
                    tag="p"
                    class="text-caption font-bold"
                  >
                  </i18n-t>
                </div>
              </div>
              <div v-else class="grid grid-cols-12">
                <div class="col-span-12 relative -top-1">
                  <!-- admin allow AV is disabled -->
                  <i18n-t
                    scope="global"
                    :keypath="'advancedVault.infoMessage.adminAvDisabled'"
                    tag="p"
                    class="text-caption relative top-1 font-bold"
                  >
                    <template v-slot:mailTo>
                      <a
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        href="mailto:support@spreedly.com"
                        >{{ $t("spreedlySupport") }}</a
                      >
                    </template>
                  </i18n-t>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="lg:py-4 py-2"
          :class="{
            'border-b border-gray-300 ':
              currentOrganization.allow_account_updater,
          }"
        >
          <div class="grid grid-cols-3 gap-4">
            <div class="col-span-1">
              <label
                for="account-updater-enabled-toggle"
                class="ml-0 lg:ml-8 font-bold relative top-2"
              >
                {{ $t("accountUpdater.accountUpdater") }}
              </label>
            </div>

            <div class="col-span-2 text-spreedly-gray-600 px-2 lg:-px-8">
              <div
                class="grid grid-cols-12"
                v-if="currentOrganization.allow_account_updater"
              >
                <div
                  class="col-span-4 lg:col-span-3 xl:col-span-2 2xl:col-span-1"
                >
                  <InputSwitch
                    :disabled="
                      !store.hasPermission('organization.update_settings')
                    "
                    class="relative top-1"
                    input-id="account-updater-enabled-toggle"
                    v-model="v$.account_updater_enabled.$model"
                  />
                </div>
                <div
                  class="col-span-8 lg:col-span-9 xl:col-span-10 2xl:col-span-11"
                >
                  <i18n-t
                    scope="global"
                    :keypath="'accountUpdater.infoMessage.auInfo'"
                    tag="p"
                    class="text-caption font-bold"
                  >
                    <template v-slot:link>
                      <a
                        class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                        :href="docsAuUrl"
                        target="_blank"
                        >{{ $t("accountUpdater.infoMessage.learnMore") }}</a
                      >
                    </template>
                  </i18n-t>
                </div>
              </div>
              <div v-else class="grid grid-cols-12">
                <div class="col-span-12 relative -top-1">
                  <!-- admin allow AU is disabled -->
                  <i18n-t
                    scope="global"
                    :keypath="'accountUpdater.infoMessage.adminAuDisabled'"
                    tag="p"
                    class="text-caption relative top-1 font-bold"
                  >
                    <template v-slot:mailTo>
                      <a
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        href="mailto:support@spreedly.com"
                        >{{ $t("spreedlySupport") }}</a
                      >
                    </template>
                  </i18n-t>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="lg:py-4 py-2"
          v-if="currentOrganization.allow_account_updater"
        >
          <div class="grid grid-cols-3 gap-4">
            <div class="col-span-1">
              <span class="ml-0 lg:ml-8 font-bold relative top-2">
                {{ $t("accountUpdater.allowAuByEnvironment") }}
              </span>
            </div>

            <div class="col-span-2 text-spreedly-gray-600 px-2 lg:-px-8">
              <div class="grid grid-cols-12">
                <div
                  class="col-span-4 lg:col-span-3 xl:col-span-2 2xl:col-span-1"
                >
                  <InputSwitch
                    class="relative top-1"
                    input-id="allow-au-by-environment-enabled-toggle"
                    id="allow-au-by-environment-enabled-toggle"
                    aria-labelledby="allow-au-by-environment-enabled-toggle"
                    v-model="v$.allow_au_by_environment.$model"
                    :disabled="
                      !v$.account_updater_enabled.$model ||
                      !store.hasPermission('organization.update_settings')
                    "
                  />
                </div>
                <div
                  class="col-span-8 lg:col-span-9 xl:col-span-10 2xl:col-span-11"
                >
                  <i18n-t
                    scope="global"
                    :keypath="'accountUpdater.infoMessage.auEnvironmentLevel'"
                    tag="p"
                    class="text-caption font-bold"
                  >
                    <template v-slot:learnMore>
                      <a
                        class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                        :href="docsAuUrl"
                        target="_blank"
                        >{{ $t("env.infoMessage.callbackUrlLink") }}</a
                      >
                    </template>
                  </i18n-t>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        v-if="store.hasPermission('organization.update_settings')"
        class="flex flex-shrink-0 flex-wrap items-center justify-start"
      >
        <SpreedlyButton
          class="mr-4"
          :text="$t('cancel')"
          id="cancel-edit-organization-button"
          :inverse="true"
          :disabled="!v$.$anyDirty || formState === 'saving'"
          @click="resetForm()"
        ></SpreedlyButton>
        <SpreedlyButton
          :disabled="!v$.$anyDirty"
          class="!mr-0"
          id="save-organization-button"
          :icon="{ position: 'left', state: formState }"
          :text="$t(formState)"
          @click="save(v$.$invalid)"
        ></SpreedlyButton>
      </div>
    </SpreedlyContainer>
  </div>
</template>
<script setup lang="ts">
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";

import Message from "primevue/message";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";

import { storeToRefs } from "pinia";
import { reactive, ref, watch } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { required } from "@/i18n/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import router from "@/router";

import {
  type Organization,
  updateOrganization,
} from "@/services/OrganizationService";
import { onBeforeRouteLeave } from "vue-router";
import i18n from "@/i18n";

const store = useSettingsStore();
const { currentOrganization } = storeToRefs(store);
const displayErrorMessage = ref<string | null>(null);
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const submitted = ref(false);
const docsAuUrl = `${import.meta.env.VITE_DOCS_URL}/docs/account-updater`;
import { sentenceCase } from "@/services/HelperService";

watch(
  () => currentOrganization.value.key,
  () => {
    router.push({
      name: "OrganizationSettings",
    });
    resetForm();
  }
);

const state = reactive({
  name: currentOrganization.value.name,
  account_updater_enabled: currentOrganization.value.account_updater_enabled,
  allow_au_by_environment: currentOrganization.value.allow_au_by_environment,
  payment_method_management_enabled:
    currentOrganization.value.payment_method_management_enabled,
});

const rules = {
  name: { required, $autoDirty: true },
  account_updater_enabled: {},
  allow_au_by_environment: {},
  payment_method_management_enabled: {},
};

const v$ = useVuelidate(rules, state);

onBeforeRouteLeave((to, from) => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

async function save(isFormInvalid: boolean) {
  if (!v$.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = null;
  if (!store.hasPermission("organization.update_settings")) {
    displayErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }
  submitted.value = true;
  if (isFormInvalid) {
    return;
  }
  formState.value = "saving";
  const org: Organization = {
    key: currentOrganization.value.key,
    name: state.name,
    account_updater_enabled: state.account_updater_enabled,
    allow_au_by_environment: state.allow_au_by_environment,
    payment_method_management_enabled: state.payment_method_management_enabled,
  };

  if (await updateOrganization(org)) {
    await store.fillOrganizations(true);
    formState.value = "saved";
    v$.value.$reset();
  } else {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
    formState.value = "saveChanges";
  }
}

function resetForm() {
  displayErrorMessage.value = null;
  state.name = currentOrganization.value.name;
  state.account_updater_enabled =
    currentOrganization.value.account_updater_enabled;
  state.allow_au_by_environment =
    currentOrganization.value.allow_au_by_environment;
  state.payment_method_management_enabled =
    currentOrganization.value.payment_method_management_enabled;
  submitted.value = false;
  v$.value.$reset();
  formState.value = "saveChanges";
}
</script>
