<template>
  <div>
    <SpreedlyPageTitle
      v-if="gateway"
      :title="gateway.name || id"
      breadcrumbs
      :editable-title="
        gateway.key &&
        gateway.state !== 'redacted' &&
        store.hasOrganizationMembership(gateway.organization_key)
      "
      :tabs="store.hasPermission('transaction.read') && !!gateway?.key"
    >
      <template v-slot:edit-title>
        <SpreedlyEditableTitle
          :has-permission="store.hasPermission('gateway.update')"
          :required="false"
          v-model:title="gateway.description"
          :alt-title="gateway.name"
          v-model:title-update-successful="titleUpdateSuccessful"
          @update-title="updateTitle"
        ></SpreedlyEditableTitle>
        <div class="lg:mt-2" v-if="tag.displayTag">
          <Tag
            class="mx-4"
            :value="tag.value"
            :severity="tag.severity"
            rounded
          ></Tag>
        </div>
      </template>
      <template v-slot:breadcrumbs>
        <Button
          v-if="isMarketplaceGateway"
          type="button"
          id="partner-gateway-list"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700"
          icon="pi pi-arrow-left"
          icon-pos="left"
          text
          :label="
            $t('gatewayTypeConnections', { gatewayType: gatewayOption?.name })
          "
          @click="
            router.push({
              name: 'PartnerGatewayList',
              params: { id: props.gatewayType },
            })
          "
        ></Button>
        <Button
          type="button"
          id="gateway-list"
          v-else
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700"
          icon="pi pi-arrow-left"
          icon-pos="left"
          text
          :label="$t('gateway_.allGatewayConnections')"
          @click="router.push({ name: 'GatewayList' })"
        ></Button>
      </template>
      <template v-slot:nav-tabs>
        <button
          type="button"
          id="gateway-details"
          class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
          :class="{
            '!text-spreedly-blue-600 !bg-spreedly-blue-300': $route.name.match(
              'Gateway\\w*DetailSummary'
            ),
          }"
          :disabled="gateway && gateway.status === 'Error'"
          @click="
            isMarketplaceGateway
              ? router.push({
                  name: 'PartnerGatewayDetailSummary',
                  params: {
                    id: gateway.key,
                    gatewayType: gatewayOption?.gateway_type,
                  },
                })
              : router.push({
                  name: 'GatewayDetailSummary',
                })
          "
        >
          {{ sentenceCase($t("details")) }}
        </button>
        <button
          type="button"
          id="gateway-transactions"
          class="bg-transparent p-2 rounded whitespace-nowrap hover:bg-spreedly-blue-300 enabled:hover:bg-spreedly-blue-300 enabled:hover:text-spreedly-blue-600 enabled:focus:shadow-none enabled:focus:bg-spreedly-blue-300 enabled:focus:text-spreedly-blue-600 border-none text-spreedly-blue-600 mr-2"
          :class="{
            '!text-spreedly-blue-600 !bg-spreedly-blue-300': $route.name.match(
              'Gateway\\w*Transactions'
            ),
          }"
          :disabled="gateway?.status === 'Error'"
          @click="
            isMarketplaceGateway
              ? router.push({
                  name: 'PartnerGatewayTransactions',
                })
              : router.push({ name: 'GatewayTransactions' })
          "
        >
          {{ $t("gateway_.recentTransactions") }}
        </button>
      </template>
    </SpreedlyPageTitle>
    <router-view
      v-if="gateway"
      v-model:gateway="gateway"
      @reload-gateway="getGateway(id)"
    />
  </div>
</template>
<script lang="ts" setup>
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyEditableTitle from "@/components/SpreedlyEditableTitle.vue";
import { useRouter } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import { sentenceCase } from "@/services/HelperService";
import { fetchGateway, updateGateway } from "@/services/GatewayService";
import i18n from "@/i18n";
import Tag from "primevue/tag";
import Button from "primevue/button";

const router = useRouter();

const store = useSettingsStore();
const { currentEnvironment, currentOrganization } = storeToRefs(store);
const gateway = ref();
const titleUpdateSuccessful = ref<boolean | null>(null);
const loadingGateways = ref(false);
const ignoreOrgOrEnvChange = ref(false);

const tag = computed(() => {
  const displaySandboxTag =
    gateway.value?.sandbox || gateway.value?.state === "redacted";
  if (displaySandboxTag && gateway.value.state === "redacted") {
    return {
      displayTag: displaySandboxTag,
      severity: "info",
      value: i18n.global.t("redacted"),
    };
  }
  return {
    displayTag: displaySandboxTag,
    severity: "success",
    value: i18n.global.t("sandbox"),
  };
});

const props = defineProps<{
  id: string;
  gatewayType?: string;
}>();

const isMarketplaceGateway = !!props.gatewayType;

const gatewayOption = store.gatewayOptions.find(
  (o) => o.gateway_type === props.gatewayType
);

onMounted(async () => {
  await getGateway(props.id);
});

const updateTitle = async (title: string) => {
  const response = await updateGateway(
    currentOrganization.value.key,
    currentEnvironment.value.key as string,
    { description: title, key: gateway.value.key }
  );
  if (response) {
    await getGateway(props.id);
    titleUpdateSuccessful.value = true;
  }
};

async function getGateway(gatewayKey: string) {
  loadingGateways.value = true;
  gateway.value = await fetchGateway(
    currentOrganization.value.key,
    currentEnvironment.value.key as string,
    gatewayKey
  ).catch(() => {
    return {
      status: i18n.global.t("error"),
      message: i18n.global.t("notFoundInOrg"),
    };
  });

  if (gateway.value?.key) {
    ignoreOrgOrEnvChange.value = true;
    await store.reconcileCurrentEnvironment(
      gateway.value.environment_key,
      gateway.value.organization_key
    );
    ignoreOrgOrEnvChange.value = false;
  }
  loadingGateways.value = false;
}

watch(
  () => props.id,
  () => {
    getGateway(props.id);
  }
);

watch(currentEnvironment, () => {
  if (!ignoreOrgOrEnvChange.value) {
    router.push({
      name: isMarketplaceGateway.value ? "Marketplace" : "GatewayList",
    });
  }
});
</script>
