<template>
  <div class="flex flex-col bg-spreedly-blue-600">
    <nav class="px-2 pb-2">
      <a
        :href="docsUrl"
        target="_blank"
        class="w-full rounded-md mb-2 text-left text-white group flex items-center py-2 text-base font-normal hover:text-spreedly-blue-600 hover:bg-spreedly-blue-300"
      >
        <SpreedlyIcon
          name="file-document-multiple"
          class="mr-3 ml-4 flex-shrink-0 h-6 w-6"
        ></SpreedlyIcon>
        <span class="flex-1">{{ $t("documentation.documentation") }}</span>
        <i class="pi pi-external-link text-xs mr-4"></i>
      </a>
    </nav>

    <hr class="solid mx-2" />
    <div
      tabindex="0"
      class="flex flex-col pl-4 pr-6 py-2 rounded-md text-white hover:cursor-pointer mr-2 ml-2 cursor-pointer hover:pl-4 my-2 relative rounded-item hover:text-spreedly-blue-600 hover:bg-spreedly-blue-300"
      @click="toggle"
      @keyup.enter="toggle"
      role="button"
      aria-haspopup="true"
      :title="currentOrganization.name"
    >
      <span
        class="font-normal text-xs text-ellipsis whitespace-nowrap overflow-hidden"
        >{{ user.email }}</span
      >
      <span class="font-bold text-ellipsis whitespace-nowrap overflow-hidden">{{
        currentOrganization.name
      }}</span>
      <SpreedlyIcon
        class="px-2 absolute inset-y-4 right-0"
        name="chevron-right"
      ></SpreedlyIcon>
    </div>
    <Menu
      :ref="(el: Menu & Element) => {
        menu = el; return undefined
      }"
      :pt="{ root: { class: 'font-sans' } }"
      :model="items"
      id="overlay_menu"
      :popup="true"
    />
  </div>
</template>
<script setup lang="ts">
import Menu from "primevue/menu";
import { useAuth0 } from "@auth0/auth0-vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import i18n from "@/i18n";
import type { MenuItem } from "primevue/menuitem";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
import { useSidebar } from "@/composables/useSidebar";
import { sentenceCase } from "@/services/HelperService";
import type { Organization } from "@/services/OrganizationService";
import { fetchBilling } from "@/services/OrganizationService";

const { logout } = useAuth0();
const router = useRouter();
const docsUrl = import.meta.env.VITE_DOCS_URL;
const { isOpen } = useSidebar();

const store = useSettingsStore();
const { user, currentOrganization } = storeToRefs(store);

function handleLogout() {
  sessionStorage.clear();
  logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
}

watch(
  () => currentOrganization.value.key,
  () => {
    items.value = getItemsList();
  }
);

const menu = ref();
const items = ref(getItemsList());

function navigateAndCloseMenu(navigateTo: string) {
  router.push({
    name: navigateTo,
  });
  isOpen.value = false;
}

function getItemsList() {
  return [
    {
      label: "Organization",
      items: [
        {
          label: sentenceCase(i18n.global.t("settings")),
          class: "organization-settings-nav",
          command: () => navigateAndCloseMenu("OrganizationSettings"),
        },
        {
          label: i18n.global.t("report", 2),
          class: "organization-reports-nav",
          visible: store.hasPermission("dashboard.read"),
          command: () => navigateAndCloseMenu("ReportOrganizationOverview"),
        },
        {
          label: i18n.global.t("accessSecret.accessSecret", 2),
          class: "organization-access-secrets-nav",
          visible: store.hasPermission("organization.list_access_secrets"),
          command: () => navigateAndCloseMenu("OrganizationAccessSecrets"),
        },
        {
          label: i18n.global.t("user.user", 2),
          class: "organization-users-nav",
          visible: store.hasPermission("organization.assign_roles"),
          command: () => navigateAndCloseMenu("OrganizationUserList"),
        },
        {
          label: i18n.global.t("activityLog.activityLog"),
          class: "organization-activity-log-nav",
          visible: store.hasPermission("activity_log.read"),
          command: () => navigateAndCloseMenu("OrganizationActivityLog"),
        },
        {
          label: i18n.global.t("manageBilling"),
          class: "organization-billing-nav",
          visible:
            store.hasPermission("billing_link.read") &&
            currentOrganization.value.can_access_chargify,
          icon: "pi pi-external-link",
          command: () => goToBilling(),
        },
      ],
    },
    { separator: true, visible: store.organizations.length > 1 },
    {
      visible: store.organizations.length > 1,
      label: i18n.global.t("org.switch"),
      items: getListOfOrgs(),
    },
    { separator: true },
    {
      class: "profile-settings-nav",
      label: i18n.global.t("profile"),
      command: () => navigateAndCloseMenu("ProfileSettings"),
    },
    {
      class: "signout-nav",
      label: i18n.global.t("signOut"),
      command: () => handleLogout(),
    },
  ];
}

function getListOfOrgs() {
  const orgList: MenuItem[] = [];

  if (store.organizations.length > 1) {
    store.organizations.forEach((org: Organization) => {
      if (org.key !== currentOrganization.value.key) {
        orgList.push({
          class: "other-organization-nav",
          label: org.name,
          command: () => changeOrgAndCloseMenu(org),
        });
      }
    });
  }
  return orgList;
}

function changeOrgAndCloseMenu(org: Organization) {
  store.changeCurrentOrganization(org);
  isOpen.value = false;
}

async function goToBilling() {
  const path = await fetchBilling(store.currentOrganization.key);
  window.open(path, "_blank");
}

const toggle = (event: Event) => {
  menu.value.toggle(event);
};
</script>
