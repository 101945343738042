<template>
  <SpreedlyPageTitle :title="$t('transactions.transactions')">
  </SpreedlyPageTitle>
  <SpreedlySubtitle :title="$t('transactions.recent')">
    <template v-slot:info-text>
      <div
        class="flex flex-col md:flex-col lg:flex-row lg:justify-between pb-8 text-spreedly-gray-600"
      >
        <i18n-t
          scope="global"
          :keypath="'infoMessage.recentTransactions'"
          tag="p"
          class="text-caption"
        >
        </i18n-t>
      </div>
    </template>
  </SpreedlySubtitle>
  <SpreedlyTable
    :headers="headers"
    :caption="$t('environmentTransactionsTable')"
    :rows="transactions"
    :sort="sort"
    :custom-columns="false"
    show-refresh
    :is-loading="isLoading"
    @refresh-data="getRecentTransactions"
  >
  </SpreedlyTable>
</template>
<script lang="ts" setup>
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyTable from "@/components/SpreedlyTable.vue";

import { onMounted, ref, watch } from "vue";
import type { Header, Row } from "@/models/spreedly-table.model";
import { fetchRecentTransactions } from "@/services/TransactionService";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";

const store = useSettingsStore();
const { currentOrganization, currentEnvironment } = storeToRefs(store);
const transactions = ref<Row[]>([]);
const isLoading = ref();

onMounted(() => {
  getRecentTransactions();
});

watch(
  () => currentEnvironment.value.key,
  () => {
    getRecentTransactions();
  }
);

async function getRecentTransactions() {
  isLoading.value = true;
  try {
    transactions.value = await fetchRecentTransactions(
      currentOrganization.value.key,
      currentEnvironment.value.key as string
    );
  } finally {
    isLoading.value = false;
  }
}

const sort = {
  field: "token",
  order: "asc",
};

const headers: Header[] = [
  {
    field: "token",
    sortable: false,
    type: "Link",
  },
  {
    field: "transaction_type",
    sortable: true,
    type: "String",
    alias: "transactions.type",
  },
  {
    field: "state",
    sortable: true,
    type: "ChipColumn",
    alias: "status",
  },
  {
    field: "payment_method",
    sortable: true,
    type: "String",
  },
  {
    field: "gateway_token",
    sortable: false,
    type: "Link",
    alias: "gateway",
  },
  {
    field: "time",
    sortable: true,
    type: "DateString",
  },
  {
    field: "amount",
    sortable: true,
    type: "String",
  },
];
</script>
