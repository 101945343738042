<template>
  <div class="flex flex-row w-full h-[calc(100vh-200px)]" v-if="workflow">
    <SpreedlyContainer
      v-if="buildingWorkflow"
      class="h-full !mb-0 !pb-0 w-full"
    >
      <WorkflowVerticalStepper :position="0">
        <Skeleton width="200px" height="32px" class="mb-2"></Skeleton>
        <Skeleton width="313px" height="40px"></Skeleton>
      </WorkflowVerticalStepper>
      <WorkflowVerticalStepper :position="1">
        <Skeleton width="200px" height="32px" class="mb-2"></Skeleton>
        <Skeleton width="585px" height="102px"></Skeleton>
      </WorkflowVerticalStepper>
    </SpreedlyContainer>
    <SpreedlyContainer v-else class="h-full !mb-0 !pb-0 w-full">
      <Message
        v-if="
          router.options.history.state.successMessage && action === 'update'
        "
        class="-mx-8 -mt-8 w-[calc(100%+4rem)] justify-start"
        severity="success"
      >
        <i18n-t
          scope="global"
          :keypath="'successMessage.workflowCreated'"
          tag="p"
        >
          <template v-slot:workflow>
            <span class="font-bold">{{ workflow.name }}</span>
          </template>
        </i18n-t>
      </Message>
      <Message
        v-if="displayErrorMessage"
        class="-mx-8 -mt-8 w-[calc(100%+4rem)] justify-start"
        severity="error"
        :closable="false"
        >{{ displayErrorMessage }}
      </Message>
      <div
        class="flex flex-col justify-between"
        :class="
          router.options.history.state.successMessage && action === 'update'
            ? 'h-[calc(100%-64px)]'
            : 'h-full'
        "
      >
        <div class="flex flex-row">
          <form
            @keyup.enter="save(v.$invalid)"
            class="relative"
            @submit.prevent="save(v.$invalid)"
            :class="width >= 800 ? 'w-[calc(100%-209.56px)]' : 'w-[95%]'"
          >
            <WorkflowVerticalStepper :position="0">
              <div class="flex flex-col" v-if="action === 'update'">
                <span class="font-bold pb-2">{{
                  `"${workflow.name}" ${$t("key")}`
                }}</span>
                <SpreedlyCopyToClipboard
                  class="bg-spreedly-blue-100 rounded-lg !mb-2 lg:mb-4"
                  :token="workflow.key as string"
                ></SpreedlyCopyToClipboard>
              </div>
              <div v-else-if="action === 'create'">
                <div class="field">
                  <label
                    class="text-sm"
                    :class="{
                      'p-error': v.name.$invalid && submitted,
                    }"
                    for="workflow-name-input"
                    >{{ $t("workflows.name") }}</label
                  >
                  <div class="mt-2 pb-4 w-[300px]">
                    <InputText
                      class="mx-2 w-full"
                      maxlength="50"
                      v-model.trim="v.name.$model"
                      id="workflow-name-input"
                      type="text"
                    />
                    <div v-if="v.name.$invalid && submitted" class="mt-0">
                      <small class="p-error">{{
                        v.name.required.$message
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </WorkflowVerticalStepper>
            <WorkflowStep
              v-for="step in steps"
              :step="step"
              :key="step.uuid"
            ></WorkflowStep>
          </form>
          <Button
            v-if="
              !togglePaymentCapabilities &&
              width < 800 &&
              store.hasComposerEnabledGatewayConnections()
            "
            @click="togglePaymentCapabilities = true"
            :title="$t('viewCapabilities')"
            :aria-label="$t('viewCapabilities')"
            id="view-payment-capabilities"
            type="button"
            icon="pi pi-list"
            class="danger p-button-text p-button-rounded !shadow-none relative inset-y-0"
          ></Button>
          <div
            v-else-if="
              !togglePaymentCapabilities &&
              width >= 800 &&
              store.hasComposerEnabledGatewayConnections()
            "
          >
            <SpreedlyButton
              id="view-payment-capabilities"
              :text="$t('viewCapabilities')"
              inverse
              @click="togglePaymentCapabilities = true"
            ></SpreedlyButton>
          </div>
        </div>
        <div class="flex flex-row justify-between my-8">
          <div class="flex flex-shrink-0 flex-wrap items-center justify-start">
            <SpreedlyButton
              class="mr-4"
              :text="$t('reset')"
              :id="`cancel-${action}-workflow-button`"
              :inverse="true"
              :disabled="disableForm()"
              @click="reset()"
            ></SpreedlyButton>
            <SpreedlyButton
              class="!mr-0"
              :disabled="disableForm()"
              :id="`${action}-workflow-button`"
              :icon="{ position: 'left', state: formState }"
              :text="$t(formState)"
              @click="save(v.$invalid)"
            ></SpreedlyButton>
          </div>
          <div class="flex flex-shrink-0 justify-end mt-4 items-center">
            <button
              v-if="
                store.hasPermission('workflow.delete') && action === 'update'
              "
              :disabled="
                workflow.key === currentEnvironment.default_workflow_key
              "
              v-tooltip.bottom="
                workflow.key === currentEnvironment.default_workflow_key
                  ? $t('workflows.deleteTooltip')
                  : null
              "
              type="button"
              @click="confirmDeleteWorkflow"
              id="delete-workflow-button"
              class="px-4 py-2 max-h-[42px] inline-flex disabled:cursor-not-allowed justify-center items-center rounded whitespace-nowrap text-spreedly-blue-600 disabled:text-spreedly-gray-400 hover:text-spreedly-blue-700 cursor-pointer"
            >
              {{ $t("workflows.delete") }}
            </button>
          </div>
        </div>
      </div>
    </SpreedlyContainer>
    <PaymentCapabilitiesDrawer
      :composer="true"
      v-model:toggle-payment-capabilities="togglePaymentCapabilities"
    ></PaymentCapabilitiesDrawer>
    <ConfirmDialog
      group="delete-workflow-warning"
      id="delete-dialog"
    ></ConfirmDialog>
  </div>
</template>
<script lang="ts" setup>
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import WorkflowVerticalStepper from "@/components/WorkflowVerticalStepper.vue";
import PaymentCapabilitiesDrawer from "@/components/PaymentCapabilitiesDrawer.vue";
import WorkflowStep from "@/components/WorkflowStep.vue";
import Skeleton from "primevue/skeleton";
import Message from "primevue/message";
import Button from "primevue/button";
import InputText from "primevue/inputtext";

import {
  computed,
  onBeforeMount,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import i18n from "@/i18n";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import router from "@/router";
import { useVuelidate } from "@vuelidate/core";
import {
  listRecoverConfigurations,
  listFailureReasons,
} from "@/services/RecoverConfigurationsService";
import {
  createWorkflow,
  deleteWorkflow,
  updateWorkflow,
} from "@/services/WorkflowService";
import { onBeforeRouteLeave } from "vue-router";
import {
  setGateways,
  resetComposable,
  useWorkflow,
  setSteps,
} from "@/composables/useWorkflow";
import { deepCopy } from "@/services/HelperService";
import { useBreakpoints } from "@/composables/useBreakpoints";
import { required } from "@vuelidate/validators";
import { updateEnvironment } from "@/services/EnvironmentService";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";

const displayErrorMessage = ref<string | null>(null);
const store = useSettingsStore();
const { currentEnvironment, currentOrganization } = storeToRefs(store);
const {
  workflow,
  action,
  formState,
  submitted,
  steps,
  recoverConfigurations,
  failureReasons,
} = useWorkflow();
const togglePaymentCapabilities = ref(false);
const { width } = useBreakpoints();
const buildingWorkflow = ref(true);
const confirm = useConfirm();

const emit = defineEmits(["refresh"]);

onBeforeMount(async () => {
  await setGateways(
    currentOrganization.value.key,
    currentEnvironment.value.key as string
  );
  setSteps();
  await setRecoverConfigurations();
  buildingWorkflow.value = false;
});

onUnmounted(() => {
  resetComposable();
});

onBeforeRouteLeave(() => {
  if (v.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

const confirmDeleteWorkflow = () => {
  confirm.require({
    group: "delete-workflow-warning",
    message: i18n.global.t("confirmations.deleteWorkflow.message"),
    header: i18n.global.t("confirmations.deleteWorkflow.header", {
      workflowName: workflow.value?.name,
    }),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("workflows.delete"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: async () => {
      try {
        await deleteWorkflow(
          currentOrganization.value.key,
          currentEnvironment.value.key as string,
          workflow.value?.key!
        );
        resetComposable();
        await router.push({ name: "WorkflowList" });
      } catch (err) {
        displayErrorMessage.value = i18n.global.t("workflows.deleteError");
      }
    },
  });
};

async function setRecoverConfigurations() {
  try {
    recoverConfigurations.value = await listRecoverConfigurations(
      store.currentOrganization.key,
      store.currentEnvironment.key!
    );

    failureReasons.value = await listFailureReasons(
      store.currentOrganization.key,
      store.currentEnvironment.key!
    );
  } catch (err) {
    displayErrorMessage.value = i18n.global.t("recover.httpError", {
      support: "support@spreedly.com",
    });
  }
}

const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("organization.create_workflow");
});

const state = reactive({
  name: "",
});
const rules = {
  name: action.value === "create" ? { required } : {},
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

watch(
  () => v.value.$anyDirty,
  () => {
    if (v.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

async function save(isFormInvalid: boolean) {
  if (!v.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = null;

  if (!hasPermission.value) {
    displayErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }

  submitted.value = true;
  if (isFormInvalid) {
    return;
  }

  try {
    formState.value = "saving";
    const workflow = await formatFunctionCall().call(null);

    if (action.value === "create") {
      if (!currentEnvironment.value.default_workflow_key) {
        let env = deepCopy(store.currentEnvironment);
        env.default_workflow_key = workflow.key;
        await updateEnvironment(currentOrganization.value.key, env);
        await store.fillEnvironments(true);
      }

      v.value.$reset();
      submitted.value = false;
      formState.value = "saveChanges";

      await router.push({
        name: "WorkflowSettings",
        params: {
          id: workflow.key,
        },
        state: { successMessage: "true" },
      });
    } else {
      emit("refresh");
    }
  } catch (err) {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
  } finally {
    v.value.$reset();
    submitted.value = false;
    formState.value = "saveChanges";
  }
}
function formatFunctionCall() {
  if (action.value === "create") {
    return createWorkflow.bind(
      null,
      currentOrganization.value.key,
      currentEnvironment.value.key!,
      {
        workflow: {
          name: state.name,
          environment_key: currentEnvironment.value.key,
          steps: steps.value,
        },
      }
    );
  }

  return updateWorkflow.bind(
    null,
    currentOrganization.value.key,
    currentEnvironment.value.key!,
    workflow.value?.key!,
    {
      workflow: {
        name: workflow.value?.name || "",
        steps: steps.value,
      },
    }
  );
}

function disableForm() {
  return (
    !v.value.$anyDirty ||
    formState.value === "saving" ||
    !store.hasComposerEnabledGatewayConnections() ||
    !hasPermission
  );
}

async function reset() {
  v.value.$reset();
  state.name = "";
  setSteps();
  await setGateways(
    currentOrganization.value.key,
    currentEnvironment.value.key as string
  );
  displayErrorMessage.value = null;
  submitted.value = false;
}
</script>
