<template>
  <div>
    <SpreedlyPageTitle :title="$t('env.signingSecret', 2)">
      <template v-slot:info>
        <div
          class="flex flex-col md:flex-col lg:flex-row lg:justify-between pb-4"
        >
          <i18n-t
            :keypath="'infoMessage.signingSecret'"
            tag="p"
            scope="global"
            class="text-caption"
          >
          </i18n-t>
        </div>
      </template>
    </SpreedlyPageTitle>

    <SpreedlyContainer>
      <Message
        v-if="displayErrorMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="error"
        :closable="false"
        >{{ $t("errorMessage.signingSecret") }}
      </Message>
      <div class="mb-8" v-if="signingSecret">
        <div class="flex flex-col">
          <div
            class="pl-2 pr-10 bg-gray-100 relative py-2 w-full lg:w-[calc(74ch)] rounded-lg border-none focus:outline-none inline-block"
          >
            <input
              v-if="visible"
              :title="$t('copyToClipboard')"
              class="hover:cursor-pointer w-full bg-transparent focus:outline-none overflow-hidden ellipse whitespace-nowrap"
              readonly
              @click="copySecret(signingSecret)"
              v-model="signingSecret"
              v-tooltip.focus="$t('copied')"
            />

            <div v-else class="overflow-hidden ellipse whitespace-nowrap">
              {{
                "****************************************************************"
              }}
            </div>

            <button
              id="toggle-visibility-on"
              type="button"
              v-if="!visible"
              class="px-2 w-10 h-full absolute inset-y-0 right-0 text-spreedly-blue-600 hover:text-spreedly-blue-700 hover:cursor-pointer"
              @click="visible = !visible"
            >
              <SpreedlyIcon
                name="eye-outline"
                :title="$t('show')"
              ></SpreedlyIcon>
            </button>
            <button
              type="button"
              id="toggle-visibility-off"
              class="px-2 w-10 h-full absolute inset-y-0 right-0 text-spreedly-blue-600 hover:text-spreedly-blue-700 hover:cursor-pointer"
              v-else
              @click="visible = !visible"
            >
              <SpreedlyIcon
                name="eye-off-outline"
                :title="$t('hide')"
              ></SpreedlyIcon>
            </button>
          </div>
        </div>
      </div>
      <SpreedlyButton
        :text="$t('env.generateSigningSecret')"
        :inverse="true"
        id="generate-signing-secret-button"
        :loading-indicator="loadingSigningSecret"
        @click="confirmUpdateSigningSecret()"
      ></SpreedlyButton>
    </SpreedlyContainer>
    <ConfirmDialog
      group="signing-secret-warning"
      id="warning-dialog"
    ></ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";

import Message from "primevue/message";
import ConfirmDialog from "primevue/confirmdialog";

import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import { useConfirm } from "primevue/useconfirm";
import { ref, watch } from "vue";
import i18n from "@/i18n";
import { regenerateSigningSecret } from "@/services/EnvironmentService";

import { copyToClipboard } from "@/services/HelperService";

const confirm = useConfirm();
const store = useSettingsStore();
const { currentOrganization, currentEnvironment } = storeToRefs(store);
const displayErrorMessage = ref(false);
const signingSecret = ref(currentEnvironment.value.signing_secret);
const loadingSigningSecret = ref(false);
const visible = ref(false);

watch(
  () => currentEnvironment.value,
  () => {
    signingSecret.value = currentEnvironment.value.signing_secret;
  }
);
watch(
  () => signingSecret.value,
  () => {
    visible.value = false;
  }
);

function copySecret(secret: string) {
  if (visible.value) {
    copyToClipboard(secret);
  }
}

const confirmUpdateSigningSecret = () => {
  confirm.require({
    group: "signing-secret-warning",
    message: i18n.global.t("confirmations.generateSigningSecret.message"),
    header: i18n.global.t("confirmations.generateSigningSecret.header"),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("env.generateSigningSecret"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: () => updateSigningSecret(),
  });
};

async function updateSigningSecret() {
  loadingSigningSecret.value = true;
  displayErrorMessage.value = false;
  if (
    await regenerateSigningSecret(
      currentOrganization.value.key,
      currentEnvironment.value.key
    )
  ) {
    await store.fillEnvironments(true);
    const newSigningSecret = store.environments.find(
      (env) => env.key === currentEnvironment.value.key
    )?.signing_secret;
    if (newSigningSecret) signingSecret.value = newSigningSecret;
  } else {
    displayErrorMessage.value = true;
  }
  loadingSigningSecret.value = false;
}
</script>
