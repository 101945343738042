<template>
  <SpreedlyContainer>
    <div v-if="showRefresh" class="flex justify-end">
      <button
        type="button"
        class="text-spreedly-blue-600 cursor-pointer inline-flex justify-center pb-2 items-center"
        @click="$emit('refreshData')"
        id="refresh"
      >
        <SpreedlyIcon name="refresh" :size="18" class="mr-1" />
        <span>{{ $t("refresh") }}</span>
      </button>
    </div>

    <div class="fixed-header">
      <table class="spreedly-table">
        <caption class="sr-only">
          {{
            caption
          }}
        </caption>
        <thead class="text-xs">
          <tr>
            <th scope="col" v-for="(col, index) in headers" :key="col.field">
              <button
                type="button"
                :id="`table-sort-${index}`"
                class="text-spreedly-gray-600 relative block p-2 w-full text-left"
                :title="$t(col.field)"
                @click="sortBy(index)"
                :disabled="!sortEnabled"
              >
                <div
                  class="px-2 w-10 h-8 absolute inset-y-4 transform -translate-y-1/2 -mx-3 my-1"
                >
                  <template v-if="sortEnabled">
                    <template v-if="sortColumn === index">
                      <SpreedlyIcon
                        class="text-spreedly-blue-600"
                        v-if="sortOrder === 'desc'"
                        name="arrow-up"
                        :aria-label="$t('sortDescending')"
                        :title="$t('sortDescending')"
                      ></SpreedlyIcon>
                      <SpreedlyIcon
                        v-else
                        class="text-spreedly-blue-600"
                        name="arrow-down"
                        :aria-label="$t('sortAscending')"
                        :title="$t('sortAscending')"
                      ></SpreedlyIcon>
                    </template>
                    <template v-else>
                      <SpreedlyIcon
                        class="text-spreedly-blue-600"
                        v-if="col.sortable"
                        name="sort"
                        :aria-label="$t('sort')"
                        :title="$t('sort')"
                      ></SpreedlyIcon>
                    </template>
                  </template>
                </div>

                <span
                  :class="{
                    'pl-8 pr-2 leading-5 py-2 w-10':
                      sortEnabled && col.sortable,
                  }"
                >
                  {{ col.alias ? $t(col.alias) : $t(col.field) }}</span
                >
              </button>
            </th>
            <slot name="custom-column-headers"></slot>
          </tr>
        </thead>
        <tbody v-if="!isLoading" :class="{ '!-top-[0.5rem] ': customRow }">
          <slot name="custom-row"></slot>
          <template v-if="numRows > 0">
            <tr v-for="(row, index) in visibleRows" :key="index">
              <td v-for="col in headers" :key="col.field">
                <router-link
                  v-if="
                    col.type === 'Link' &&
                    typeof row[col.field] === 'object' &&
                    row[col.field].displayValue
                  "
                  :id="col.field"
                  class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                  :to="row[col.field].to"
                >
                  {{ row[col.field].displayValue }}
                </router-link>
                <span
                  v-else-if="
                    col.type === 'Link' && typeof row[col.field] === 'object'
                  "
                  >{{ row[col.field].displayValue }}</span
                >
                <Chip
                  class="px-4 text-sm"
                  :class="row[col.field].class"
                  v-else-if="col.type === 'ChipColumn'"
                  :label="row[col.field].displayValue"
                />

                <span v-else-if="col.type === 'DateString'">{{
                  formatDate(row[col.field])
                }}</span>
                <span
                  v-else
                  :class="
                    col.field === 'firstName' || col.field === 'lastName'
                      ? 'sentry-mask'
                      : ''
                  "
                  >{{ row[col.field] }}</span
                >
              </td>
              <slot name="custom-columns" :row="row"></slot>
            </tr>
          </template>
          <template v-else-if="!customRow && numRows === 0">
            <tr>
              <td :colspan="numColumns" class="font-medium">
                <span>{{ $t("noData") }}</span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="isLoading">
        <SpreedlyLoadingSpinner />
      </div>
    </div>
    <slot name="table-action"></slot>
  </SpreedlyContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { formatDate } from "@/services/HelperService";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
import SpreedlyLoadingSpinner from "./SpreedlyLoadingSpinner.vue";
import type {
  Header,
  ChipColumn,
  Link,
  Row,
  Sort,
} from "@/models/spreedly-table.model";
import Chip from "primevue/chip";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";

// to use a column with a link you must specify "Link" in the type property for the header and pass the column value in the
// rows array as an object: { displayValue: string | number; to: RouteLocationRaw }

const props = defineProps<{
  headers: Header[];
  rows?: Row[];
  sort?: Sort;
  customColumns: boolean;
  caption: string;
  customRow?: boolean;
  isLoading?: boolean;
  showRefresh?: boolean;
}>();

const sortDefault = ref({ field: null, type: "asc" });
const sortColumn = ref<number | null>(null);
const sortField = ref<string>("");
const sortOrder = ref<string | null>(null);
const visibleRows = ref<unknown>({});
const tableRows = ref<Row[] | []>([]);

const sortOptions = computed(() => {
  return Object.assign(sortDefault, props.sort);
});
const sortEnabled = computed(() => {
  return sortOptions.value.field !== null && numRows.value > 0;
});
const numRows = computed(() => {
  return props.rows?.length ? props.rows.length : 0;
});
const numColumns = computed(() => {
  return props.headers.length;
});

onMounted(() => {
  loadRows();
});

watch(
  () => props.rows,
  () => {
    loadRows();
  }
);

function loadRows() {
  tableRows.value = props.rows ? props.rows?.slice(0) : [];
  if (sortEnabled.value) initSort();
  selectVisibleRows();
}

function initSort() {
  sortOrder.value = sortOptions.value.order === "asc" ? "asc" : "desc";
}

function selectVisibleRows() {
  visibleRows.value = tableRows.value.slice(0);
}

function sortBy(index: number) {
  if (sortEnabled.value && props.headers[index].sortable) {
    if (sortColumn.value === index) {
      sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
      tableRows.value.reverse();
    } else {
      sortColumn.value = index;
      sortField.value = props.headers[index].field;

      const sortedTable = sortAsc(props.headers[index].type, tableRows.value);
      if (tableRows.value === sortedTable) {
        sortOrder.value = "desc";
        tableRows.value.reverse();
      } else {
        sortOrder.value = "asc";
        tableRows.value = sortedTable;
      }
    }
    selectVisibleRows();
  }
}

function sortAsc(
  headerType: "String" | "DateString" | "Number" | "Link" | "ChipColumn",
  rows: Row[]
) {
  const header: string = sortField.value;
  const distantFuture = new Date(8640000000000000).getTime();

  switch (headerType) {
    case "ChipColumn":
      return rows.sort((a, b) =>
        ((a[header] as ChipColumn).displayValue as string).localeCompare(
          (b[header] as ChipColumn).displayValue as string
        )
      );
    case "Link":
      if (typeof (rows[0][header] as Link).displayValue === "number") {
        return rows.sort(
          (a, b) =>
            ((a[header] as Link).displayValue as number) -
            ((b[header] as Link).displayValue as number)
        );
      }
      return rows.sort((a, b) =>
        ((a[header] as Link).displayValue as string).localeCompare(
          (b[header] as Link).displayValue as string
        )
      );
    case "Number":
      return rows.sort((a, b) => (a[header] as number) - (b[header] as number));
    case "DateString":
      return rows.sort((a, b) => {
        const dateA = a[header]
          ? Date.parse(a[header] as string)
          : distantFuture;
        const dateB = b[header]
          ? Date.parse(b[header] as string)
          : distantFuture;
        return dateA - dateB;
      });
    default:
      return rows.sort((a, b) =>
        (a[header] as string).localeCompare(b[header] as string)
      );
  }
}
</script>
<style lang="css">
.fixed-header {
  overflow: auto;
  max-height: 60vh;
  width: 100%;
}

.fixed-header thead tr {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
}

table.spreedly-table {
  width: 100%;
  color: #545859;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: left;
  margin-bottom: 1rem;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}

table.spreedly-table thead tr th {
  padding: 1rem 1.5rem;
  white-space: nowrap;
  border-bottom: 1px solid rgb(209 213 219 /1);
}

table.spreedly-table tbody tr td {
  padding: 0.5rem 1.5rem 0;
  margin-bottom: -0.5rem;
  white-space: nowrap;
  border-top: 1px solid rgb(209 213 219 /1);
}

table.spreedly-table tbody tr.highlight-success-row td {
  background-color: #c8e6c9;
  padding-bottom: 0.5rem !important;
}

table.spreedly-table tbody tr.highlight-success-row td:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

table.spreedly-table tbody tr.highlight-success-row td:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

table.spreedly-table tbody tr:first-child td {
  border-top: none;
  position: relative;
}

table.spreedly-table tbody {
  position: relative;
  top: -0.875rem;
  bottom: 0.125rem;
}
</style>
