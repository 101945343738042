<template>
  <div class="step">
    <div class="top-line"></div>
    <div class="content">
      <div v-if="displayHeader" class="content-header">
        <div class="flex flex-row justify-between">
          <slot name="header"></slot>
          <Button
            type="button"
            :aria-label="`collapse-step-${position}`"
            :id="`collapse-step-${position}`"
            @click="displayStep = !displayStep"
            :icon="displayStep ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
            class="mt-2 ml-4 -mr-4 p-button-text p-button-rounded !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
            rounded
          ></Button>
        </div>
      </div>
      <div v-if="displayStep">
        <slot></slot>
      </div>
    </div>
    <div class="v-stepper">
      <div class="line"></div>
      <!--      <div class="flex flex-row">-->
      <!--        <Button-->
      <!--          :id="`add-step-button-${position}`"-->
      <!--          @click="toggleMenu"-->
      <!--          icon="pi-text-override pi pi-plus-circle"-->
      <!--          class="plus-button p-button-text p-button-rounded !text-spreedly-blue-600 bg-white !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"-->
      <!--        >-->
      <!--        </Button>-->
      <!--        <Menu-->
      <!--          :ref="(el: Menu & Element) => {-->
      <!--              menu = el; return undefined-->
      <!--            }"-->
      <!--          :pt="{ root: { class: 'font-sans' } }"-->
      <!--          :model="items"-->
      <!--          :id="`overlay_menu-${position}`"-->
      <!--          :popup="true"-->
      <!--        />-->
      <!--      </div>-->
      <div class="arrow"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";
import Menu from "primevue/menu";

import { ref } from "vue";
import i18n from "@/i18n";

defineProps<{
  position: number;
  displayHeader?: boolean;
}>();

const displayStep = ref(true);

const menu = ref();
const items = ref([
  {
    label: i18n.global.t("addRuleSet"),
  },
]);
const toggleMenu = (event) => {
  menu.value.toggle(event);
};
</script>

<style lang="css" scoped>
.step {
  display: flex;
  flex-direction: column;
  position: relative;
}

.v-stepper {
  position: relative;
}

.step:not(:first-child):not(:last-child) .top-line {
  left: 1rem;
  top: 6rem;
  bottom: 0;
  position: absolute;
  border-left: 3px solid #8f9495;
}
.step .line {
  left: 1rem;
  top: 0;
  position: absolute;
  border-left: 3px solid #8f9495;
  border-bottom: 3px solid #8f9495;
  width: 1.25rem;
  height: 6rem;
  border-bottom-left-radius: 1rem;
}
.step:not(:first-child) .line {
  height: 6rem;
}
.step .arrow {
  border: solid #8f9495;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 0.35rem;
  transform: rotate(-45deg);
  position: absolute;
  left: 1.45rem;
  top: 5.45rem;
}

.step .plus-button {
  position: absolute;
  top: 1rem;
  margin: 0;
  left: 3px;
  height: 28px;
  width: 28px;
  z-index: 10;
}

.step:last-child .line {
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  z-index: -1;
}

.step:last-child .plus-button {
  display: none;
}
.step:last-child .arrow {
  border: 3px solid white;
  z-index: -1;
}
.step:last-child {
  padding: 0;
}

.content {
  background-color: white;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  border-radius: 0.5rem;
  padding: 1rem;
  border: solid 1px rgb(229, 231, 235);
  color: #545859;
  flex-direction: row;
  width: fit-content;
  position: relative;
  margin-top: 3rem;
}

.step:not(:first-child) .content {
  margin-left: 3rem;
}
.content .content-header {
  padding: 0 2.5rem 1rem;
  margin: 0 -1.5rem 0.5rem;
}
</style>
