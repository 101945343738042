<template>
  <div>
    <div v-if="store.hasPermission('organization.create_access_secret')">
      <Message
        severity="success"
        :closable="false"
        class="w-full"
        v-if="newAccessSecret"
      >
        {{ $t("accessSecret.copySecret") }}
      </Message>
      <Message
        severity="error"
        :closable="false"
        class="w-full"
        v-if="displayErrorMessage"
      >
        {{ $t("errorMessage.generic") }}
      </Message>
      <SpreedlySubtitle class="mt-6 mb-4" :title="$t('accessSecret.add', 2)">
      </SpreedlySubtitle>
      <SpreedlyContainer>
        <form
          class="p-fluid mb-4 text-spreedly-gray-700"
          @submit.prevent="save(v$.$invalid)"
        >
          <div class="field">
            <label
              class="font-bold text-sm"
              for="name-input"
              :class="{ 'p-error': v$.name.$invalid && submitted }"
              >{{ `${$t("accessSecret.create.input")}` }}</label
            >
            <div class="p-float-label mt-2 w-full lg:w-2/3 2xl:w-1/3 pb-4">
              <InputText
                id="name-input"
                v-model.trim="v$.name.$model"
                autofocus
                :class="{ 'p-invalid': v$.name.$invalid && submitted }"
                aria-describedby="name-error"
                type="text"
              />
            </div>
            <div
              v-if="
                (v$.name.$invalid && submitted) || v$.name.$pending.$response
              "
              class="-mt-4 mb-4"
            >
              <small class="p-error" v-if="v$.name.required.$invalid">{{
                v$.name.required.$message
              }}</small>
              <small class="p-error" v-else-if="v$.name.maxLength.$invalid">{{
                v$.name.maxLength.$message
              }}</small>
            </div>
          </div>
        </form>

        <div class="flex flex-shrink-0 flex-wrap items-center justify-start">
          <SpreedlyButton
            class="mr-4"
            :text="$t('cancel')"
            :inverse="true"
            :disabled="!v$.$anyDirty || formState === 'saving'"
            @click="reset()"
            id="cancel-create-access-secret-button"
          ></SpreedlyButton>
          <SpreedlyButton
            :disabled="!v$.$anyDirty"
            class="!mr-0"
            :icon="{ position: 'left', state: formState }"
            :text="$t(formState)"
            id="create-access-secret-button"
            @click="save(v$.$invalid)"
          ></SpreedlyButton>
        </div>
      </SpreedlyContainer>
    </div>

    <SpreedlySubtitle class="mt-6 mb-4" :title="$t('accessSecret.all')">
    </SpreedlySubtitle>

    <SpreedlyTable
      :custom-row="!!newAccessSecret"
      v-if="!isLoading && accessSecrets"
      :headers="headers"
      :rows="accessSecrets"
      :sort="sort"
      :custom-columns="true"
      :caption="$t('accessSecret.envTable')"
    >
      <template v-slot:custom-column-headers>
        <th v-if="store.hasPermission('access_secret.delete')"></th>
      </template>
      <template v-slot:custom-row>
        <tr v-if="newAccessSecret" class="highlight-success-row">
          <td>
            {{ newAccessSecret.name }}
          </td>
          <td>
            <SpreedlyCopyToClipboard
              class="relative inset-y-0.5"
              inline
              :token="newAccessSecret.access_secret"
            ></SpreedlyCopyToClipboard>
          </td>
          <td>
            {{ formatDate(newAccessSecret.created_at) }}
          </td>
          <td
            v-if="store.hasPermission('access_secret.delete')"
            class="flex justify-end"
          >
            <Button
              @click="confirmDelete(newAccessSecret)"
              :title="$t('delete')"
              :aria-label="$t('delete')"
              id="delete-access-secret-button"
              icon="pi pi-trash"
              class="danger p-button-text p-button-rounded !shadow-none relative inset-y-0"
            ></Button>
          </td>
        </tr>
      </template>
      <template v-slot:custom-columns="{ row }">
        <td
          v-if="store.hasPermission('access_secret.delete')"
          class="flex justify-end"
        >
          <Button
            @click="confirmDelete(row)"
            :title="$t('delete')"
            :aria-label="$t('delete')"
            type="button"
            id="delete-access-secret"
            icon="pi pi-trash"
            class="danger p-button-text p-button-rounded !shadow-none relative -inset-y-[0.25rem]"
          ></Button>
        </td>
      </template>
    </SpreedlyTable>
    <div
      v-else
      class="overflow-x-auto bg-white relative border drop-shadow-sm !rounded-lg py-4"
    >
      <SpreedlyLoadingSpinner />
    </div>
    <ConfirmDialog
      group="delete-access-secret"
      id="delete-dialog"
    ></ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watch } from "vue";
import SpreedlyTable from "@/components/SpreedlyTable.vue";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Message from "primevue/message";
import ConfirmDialog from "primevue/confirmdialog";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import {
  type AccessSecret,
  deleteAccessSecret,
  fetchAccessSecrets,
  generateAccessSecret,
} from "@/services/AccessSecretService";
import i18n from "@/i18n";
import { useConfirm } from "primevue/useconfirm";
import { required } from "@/i18n/i18n-validators";
import { maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import { formatDate } from "@/services/HelperService";
import { onBeforeRouteLeave } from "vue-router";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import type { Environment } from "@/services/EnvironmentService";

const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");

const props = defineProps<{
  environment?: Environment;
}>();

const store = useSettingsStore();
const accessSecrets = ref<AccessSecret[]>();
const { currentOrganization, currentEnvironment } = storeToRefs(store);
const confirm = useConfirm();
const submitted = ref(false);
const displayErrorMessage = ref(false);
const newAccessSecret = ref<AccessSecret | null>(null);

onMounted(() => {
  loadAccessSecretList();
});

const state = reactive({ name: "" });
const rules = {
  name: {
    required,
    maxLength: maxLength(100),
  },
};

const v$ = useVuelidate(rules, state);

watch(
  () => currentEnvironment.value.key,
  () => {
    loadAccessSecretList();
  }
);

watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

onBeforeRouteLeave((to, from) => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

const isLoading = ref(false);

function reset() {
  submitted.value = false;
  v$.value.$reset();
  state.name = "";
}

async function save(isFormInvalid: boolean) {
  if (!v$.value.$anyDirty || formState.value === "saving") {
    return;
  }

  submitted.value = true;
  if (isFormInvalid) {
    return;
  }
  displayErrorMessage.value = false;
  await loadAccessSecretList();
  formState.value = "saving";
  try {
    newAccessSecret.value = await generateAccessSecret(
      state.name,
      currentOrganization.value.key,
      props.environment?.key
    );
    if (newAccessSecret.value) {
      formState.value = "saved";
      reset();
    }
  } catch (error) {
    displayErrorMessage.value = true;
    formState.value = "saveChanges";
  }
}

async function loadAccessSecretList() {
  isLoading.value = true;
  newAccessSecret.value = null;
  accessSecrets.value = await fetchAccessSecrets(
    currentOrganization.value.key,
    props.environment?.key
  ).then((data) => {
    data.forEach(
      (accessSecret) => (accessSecret.access_secret += "**********")
    );
    return data.sort((a, b) => a.name.localeCompare(b.name));
  });
  isLoading.value = false;
}

const headers = [
  {
    field: "name",
    sortable: true,
    type: "String",
  },
  {
    field: "access_secret",
    sortable: true,
    type: "String",
  },
  {
    field: "created_at",
    sortable: true,
    type: "DateString",
  },
];
const sort = {
  field: "name",
  order: "asc",
};

const confirmDelete = (secret: AccessSecret) => {
  confirm.require({
    group: "delete-access-secret",
    message: i18n.global.t("confirmations.deleteSecret.message"),
    header: i18n.global.t("confirmations.deleteSecret.header", {
      accessSecret: secret.name,
    }),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("accessSecret.delete"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: () => deleteSecret(secret),
  });
};

async function deleteSecret(secret: { name: string; key: string }) {
  if (secret.key) {
    isLoading.value = true;
    try {
      await deleteAccessSecret(
        currentOrganization.value.key,
        secret.key,
        props.environment?.key
      );
      await loadAccessSecretList();
    } catch (err) {
      alert(i18n.global.t("errorMessage.generic"));
    } finally {
      isLoading.value = false;
    }
  }
}
</script>
<style lang="css" scoped>
.success.p-button.p-button-text {
  background-color: transparent;
  color: #24844e;
  border-color: transparent;
}

.info.p-button.p-button-text {
  background-color: #0077c8;
  color: #ffffff;
  border-color: transparent;
}

.danger.p-button.p-button-text {
  background-color: transparent;
  color: #c70039;
  border-color: transparent;
}

.success.p-button.p-button-text:enabled:hover {
  background: #f3f8fc;
  color: #24844e;
  border-color: transparent;
}

.danger.p-button.p-button-text:enabled:hover {
  background: #f3f8fc;
  color: #c70039;
  border-color: transparent;
}
</style>
